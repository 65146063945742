import React, {useEffect, useState} from "react";
import {Button, Col, Container, Form} from "react-bootstrap";
import {ErrorMessage, Formik} from "formik";
import axios from "../../axios/Instances/axios-instance";
import useGlobal from "../../store/store";

const UploadNewMenu = (props) => {

    let apiError;

    const tenant_hash = props.match.params.tenant_hash;
    const menuId = props.match.params.menu_id;
    const [successful, setSuccessful] = useState(false);
    let updateSuccessful = '';

    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    )

    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0]

    const initialValues = {
        menu_file: ''
    };

    useEffect(() => {
        if (!tenant.api_key)
            tenantActions.setTenant(tenant_hash);
    }, [tenant.api_key, tenant_hash, tenantActions])

    if (successful) updateSuccessful = (<p>Die Speisekarte wurde aktualisiert!</p>);

    return (
        <Container fluid>
            <h6 className="text-center">Aktualisieren Sie Ihre Speisekarte</h6>
            {updateSuccessful}
            <Formik
                //validationSchema={}
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, {setSubmitting, setFieldError}) => {
                    //log.debug(values);
                    setTimeout(() => {
                        const menu_data = new FormData();
                        menu_data.set('file', values.menu_file)
                        menu_data.set('type', 'gastro_menu')
                        axios.post('/api/v1/media_resource', menu_data, {
                            headers: {
                                'Authorization': 'Bearer ' + auth.jwtToken
                            }
                        })
                            .then(response => {
                                axios.post('/api/plugin/admin/v1/gastro/menu/' + menuId,
                                    {
                                        resource_id: response.data.id
                                    }, {
                                        headers: {
                                            'Authorization': 'Bearer ' + auth.jwtToken,
                                            'api-key': tenant.api_key
                                        }
                                    })
                                    .then(response => {
                                        setSubmitting(false);
                                        setSuccessful(true);
                                        //history.push("/Admin/"+tenant_hash+"/menus");
                                    });
                            })
                            .catch(error => {
                                //log.debug(error)
                                apiError = 'Es ist ein Fehler aufgetreten.'
                                setSubmitting(false);
                            });

                    }, 400);
                }
                }
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      isInvalid,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="menu_file">
                                <input type="file" as={Form.Control} name="menu_file" id="menu_file"
                                       isInvalid={touched.menu_file && errors.menu_file} onChange={(event) => {
                                    setFieldValue("menu_file", event.currentTarget.files[0])
                                }}/>
                                <ErrorMessage as={Form.Control.Feedback} name="menu_file" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="text-center">
                            <p className="text-center apiError">{apiError}</p>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="text-center">
                                <Button type="submit" variant="secondary"
                                        disabled={(!isValid || !dirty) || isSubmitting}>Speisekarte
                                    aktualisieren</Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </Container>)
}

export default UploadNewMenu;