import React, {useState} from "react";
import PasswordResetForm from "../../components/Forms/passwordResetForm";
import {Container} from "react-bootstrap";

const PasswordReset = (props) => {

    const [success, setSuccess] = useState(false);

    if (success) return (
        <Container>
            {success.sent_by === 'mail' && "Wir haben Ihnen eine E-Mail mit den Anweisungen zum Zurücksetzen des Passworts gesendet."}
            {success.sent_by !== 'mail' && "Wir haben Ihnen eine SMS mit den Anweisungen zum Zurücksetzen des Passworts gesendet."}
        </Container>)


    return (
        <PasswordResetForm success={setSuccess} identifier={props.location.state && props.location.state.identifier}/>
    )

}

export default PasswordReset