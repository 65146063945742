import React from "react";
import useGlobal from "../../store/store";
import {usePromiseTracker} from "react-promise-tracker";
import areas from "../../common/constants/promise-areas";
import Spinner from "../../components/UI/Spinner/Spinner";
import {Redirect} from "react-router";

const Homepage = () => {

    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];

    const user = useGlobal(
        state => state.user,
        actions => actions.user
    )[0];

    const {promiseInProgress} = usePromiseTracker({area: areas.auth, delay: 500});

    if (promiseInProgress) return (<Spinner></Spinner>);

    if (!auth.jwtToken) {
        return (
            <Redirect to={'/login'} />
        );
    }

    if (auth.loggedInUser && user.currentCheckIn) {
        return <Redirect to={'/checkin/current'}></Redirect>
    }

    if (auth.loggedInUser) return (
        <React.Fragment>
            <h5>Hallo {auth.loggedInUser.first_name}</h5>
            <p>Sie sind aktuell nirgends eingecheckt</p>
        </React.Fragment>
    );

    return null
}

export default Homepage;