import React, {useEffect, useState} from "react";
import RegistrationForm from "../../components/Forms/RegistrationForm";
import axios from "../../axios/Instances/axios-instance";
import {Redirect} from "react-router";
import {Container} from "react-bootstrap";
import useGlobal from "../../store/store";

const RegisterAccount = (props) => {

    const localStore = require('store');

    const [preFilledValues, setPreFilledValues] = useState(null);
    const [created, setCreated] = useState(false);
    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )

    let requestId = null;
    let optInCode = null;

    if (props.requestId)
        requestId = props.requestId;

    if (props.optInCode)
        optInCode = props.optInCode;


    requestId = localStore.get('current_r');
    optInCode = localStore.get('current_c');

    useEffect(() => {
            // Update the document title using the browser API
            if (requestId && optInCode)
                axios.get('/api/plugin/v1/attendee/data/' + requestId + '/' + optInCode, {})
                    .then(response => {
                        setPreFilledValues(response.data)
                    }).catch(error => {
                });
        }, [requestId, optInCode]
    );

    return (
        <div>
            {!created && preFilledValues && (
                <Container>
                    <h6>Erstellen Sie einen Account</h6>
                    Mit ihrem Account können Sie bei Ihrem nächsten Besuch oder bei vielen teilnehmenden Partnern mit
                    nur einem Klick DS-GVO-konform einchecken.<br/><br/>
                    <RegistrationForm preFilledValues={preFilledValues} requestId={requestId} optInCode={optInCode}
                                      success={setCreated}/>
                    {/*<p className="text-center"><span className={globalClasses.ImportantLink} onClick={() => props.skipRegistration(true)}>Ohne Account fortfahren</span></p>*/}
                </Container>
            )}
            {created && preFilledValues && (
                <Container>
                    {auth.loggedInUser !== '' && (<Redirect to="/"/>)}
                </Container>
            )}
            {created && !preFilledValues && (
                <Container>
                    <h6>noch bestätigen</h6>
                </Container>
            )}
        </div>
    )
}

export default RegisterAccount