import {useField} from "formik";
import globalClasses from "../../../App.module.css";
import React from "react";
import styles from './checkbox.module.css';

const Checkbox = ({children, ...props}) => {
    const [field, meta] = useField({...props, type: 'checkbox'});
    return (
        <div style={{
            display: "flex",
            flexDirection: "row"
        }}>
            <input type="checkbox" {...field} {...props} className={styles.CheckboxStyle}/>
            <label>
                {children}
                {meta.touched && meta.error ? (
                    <div className={globalClasses.ErrorMessage}>{meta.error}</div>
                ) : null}
            </label>

        </div>
    );
};

export default Checkbox;