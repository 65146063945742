import React from "react";
import {IconContext} from "react-icons";

const IconWithText = (props) => {

    return <>
        <span style={{marginRight: "1em", float: "left"}}>
            <IconContext.Provider value={{size: "1em"}}>
                {props.children}
            </IconContext.Provider>
        </span>
        {props.text}
    </>
}

export default IconWithText;