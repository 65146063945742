import * as Yup from "yup";

export const credentialFormInitialValues = {
    last_name: '',
    first_name: '',
    mobile_phone_number: "",
    email_address: "",
    street: '',
    plz: '',
    city: '',
    terms: false,
    privacy: false,
    attendees: []
};

const CHARS_AND_UMLAUTE = 'A-Za-z-ÁÀȦÂÄǞǍĂĀÃÅǺǼǢĆĊĈČĎḌḐḒÉÈĖÊËĚĔĒẼE̊ẸǴĠĜǦĞG̃ĢĤḤáàȧâäǟǎăāãåǻǽǣćċĉčďḍḑḓéèėêëěĕēẽe̊ẹǵġĝǧğg̃ģĥḥÍÌİÎÏǏĬĪĨỊĴĶǨĹĻĽĿḼM̂M̄ʼNŃN̂ṄN̈ŇN̄ÑŅṊÓÒȮȰÔÖȪǑŎŌÕȬŐỌǾƠíìiîïǐĭīĩịĵķǩĺļľŀḽm̂m̄ŉńn̂ṅn̈ňn̄ñņṋóòôȯȱöȫǒŏōõȭőọǿơP̄ŔŘŖŚŜṠŠȘṢŤȚṬṰÚÙÛÜǓŬŪŨŰŮỤẂẀŴẄÝỲŶŸȲỸŹŻŽẒǮp̄ŕřŗśŝṡšşṣťțṭṱúùûüǔŭūũűůụẃẁŵẅýỳŷÿȳỹźżžẓǯßœŒçÇøæ';

export const REQUIRED_MOBILE = 'Bitte geben Sie Ihre Handynummer an'
export const REQUIRED_FIRST_NAME = 'Bitte geben Sie Ihren Vornamen ein';
export const REUQIRED_LAST_NAME = 'Bitte geben Sie Ihren Nachnamen ein';
export const REQUIRED_PLZ = 'Bitte geben Sie Ihre Postleitzahl ein';
export const REQUIRED_STREET = 'Bitte geben Sie Ihre Anschrift ein';
export const REQUIRED_STREET_4 = 'Mindestens 4 Zeichen';
export const REQUIRED_CITY = 'Bitte geben Sie Ihren Ort ein';
export const REQUIRED_GUEST_FIRST_NAME = 'Bitte geben Sie den Vornamen des Gastes ein';
export const REUQIRED_GUEST_LAST_NAME = 'Bitte geben Sie den Nachnamen des Gastes ein';
export const REQUIRED_TERMS = 'Bitte akzeptieren sie die AGBs.';
export const REQUIRED_PRIVACY = 'Bitte akzeptieren sie die Datenschutzbedingungen.';
export const REQUIRED_VALID_MOBILE = 'Bitte eine gültige Handynummer angeben';
export const REQUIRED_MAIL_OR_MOBILE = 'Bitte geben Sie entweder eine E-Mail Adresse oder eine Handynummer an.';
export const REQUIRED_VALID_EMAIL = 'Bitte geben Sie eine gültige E-Mail Adresse an.';
export const REQUIRED_NUMERIC_PLZ = 'Bitte nur Zahlen eingeben';
export const REQUIRED_VALID_PLZ = 'Keine gültige Postleitzahl';
export const REQUIRED_TEXTFIELD = 'Diese Angabe ist verpflichtend';
export const REQUIRED_CHECKBOX = 'Diese Angabe ist verpflichtend';
export const EMAIL_REGEX = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const MOBILE_PHONE_REGEX = /^([+](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,8}$/
export const PLZ_REGEX = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/
export const STREET_REGEX = new RegExp(`^[0-9. ${CHARS_AND_UMLAUTE}/-]*$`, 'ig')
export const CITY_REGEX = new RegExp(`^[${CHARS_AND_UMLAUTE} /'-]*$`);
export const NAME_REGEX = new RegExp(`^[ '${CHARS_AND_UMLAUTE}-]*$`);
export const MIN_ONE_NUMBER = new RegExp('\\d{1}')
export const MIN_ONE_CHAR = new RegExp(`[${CHARS_AND_UMLAUTE}]{1,}`, 'ig');

export const credentialFormValidationSchema = Yup.object().shape({
    first_name: Yup.string().required(REQUIRED_FIRST_NAME).matches(NAME_REGEX, REQUIRED_FIRST_NAME),
    last_name: Yup.string().required(REUQIRED_LAST_NAME).matches(NAME_REGEX, REUQIRED_LAST_NAME),
    plz: Yup.string().trim().required(REQUIRED_PLZ).matches(PLZ_REGEX, REQUIRED_VALID_PLZ),
    street: Yup.string().required(REQUIRED_STREET).matches(MIN_ONE_NUMBER, REQUIRED_STREET).matches(STREET_REGEX, REQUIRED_STREET).matches(MIN_ONE_CHAR, REQUIRED_STREET),
    city: Yup.string().required(REQUIRED_CITY).min(2, REQUIRED_CITY).matches(CITY_REGEX, REQUIRED_CITY),

    attendees: Yup.array(Yup.object({
        first_name: Yup.string().required(REQUIRED_GUEST_FIRST_NAME).matches(NAME_REGEX, REQUIRED_GUEST_FIRST_NAME),
        last_name: Yup.string().required(REUQIRED_GUEST_LAST_NAME).matches(NAME_REGEX, REUQIRED_GUEST_LAST_NAME)
    })),
    //      terms: Yup.boolean().required(REQUIRED_TERMS).oneOf([true],REQUIRED_TERMS),
    privacy: Yup.boolean().required(REQUIRED_PRIVACY).oneOf([true], REQUIRED_PRIVACY),
    mobile_phone_number: Yup
        .string().required(REQUIRED_MOBILE).trim().matches(MOBILE_PHONE_REGEX, REQUIRED_VALID_MOBILE),
    email_address: Yup
        .string().default('').when("mobile_phone_number", {
            is: '',
            then: Yup.string().required(REQUIRED_MAIL_OR_MOBILE).trim().matches(EMAIL_REGEX, REQUIRED_VALID_EMAIL),
            otherwise: Yup.string().when
            ('email_address', {
                is: '',
                then: Yup.string(),
                otherwise: Yup.string().trim().matches(EMAIL_REGEX, REQUIRED_VALID_EMAIL)
            })
        }),
}, ["mobile_phone_number", "email_address"])


