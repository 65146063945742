import React, {useEffect, useState} from "react";
import queryString from "query-string";
import axios from "../../axios/Instances/axios-instance";

const ConfirmAccount = (props) => {

    const [result, setResult] = useState('');
    const [redirectUrl, setRedirectUrl] = useState(undefined);

    const confirmationToken = (props.location.search) ? queryString.parse(props.location.search).ct : null;

    useEffect(() => {

        if (props.location.search) {
            if (queryString.parse(props.location.search).r)
                setRedirectUrl(queryString.parse(props.location.search).r);
        }

        axios.post("api/plugin/admin/v1/account/confirm/" + confirmationToken).then((response) => {
                if (response.status === 200)
                    setResult('SUCCESS');

            }
        ).catch((error) => {
            if (
                error.response.data.message.includes('bereits aktiviert')
            )
                setResult('IS_ALREADY_ACTIVE');
            else
                setResult('NOT_FOUND');
        });
    }, [confirmationToken, (props.location.search)]);

    console.log(redirectUrl);

    if ((result === 'SUCCESS' || result === 'IS_ALREADY_ACTIVE') && redirectUrl != null) {
        setTimeout(function () {  }, 25);
        window.location = redirectUrl;
    }

    if (result === '' || result === 'NOT_FOUND')
    return <div>Kein gültiger Request</div>
    if (result === 'SUCCESS') return <div className="text-center">Vielen Dank, Ihr Account wurde bestätigt. Sie können
        die App jetzt nutzen!</div>

    if (result === 'IS_ALREADY_ACTIVE') return <div className="text-center">Vielen Dank, Ihr Account wurde bereits
        bestätigt. Sie können die App jetzt nutzen!</div>
}

export default ConfirmAccount;
