import React from "react";
import Button from "react-bootstrap/Button";
import style from './IconButton.module.css';
import {FaTrashAlt} from "react-icons/fa";

const IconButton = (props) => {

    return <Button variant={"secondary"} {...props} className={style.IconButton}><FaTrashAlt/></Button>

}

export default IconButton;