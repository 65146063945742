import React, {useState} from "react";
import axios from "../../axios/Instances/axios-instance";
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import './ListMenu.css'
import {useHistory} from "react-router";
import useGlobal from "../../store/store";
import {usePromiseTracker} from "react-promise-tracker";
import promiseAreas from "../../common/constants/promise-areas";

const ListMenu = (props) => {

    const history = useHistory();

    const [menus, setMenus] = useState(null)
    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    )
    const tenantLoading = usePromiseTracker({area: promiseAreas.tenant});

    if (!tenant.api_key && !tenantLoading.promiseInProgress) {
        tenantActions.setTenant(props.match.params.tenant_hash)
    }

    if (tenant.api_key && !menus) {
        axios.get( '/api/plugin/v1/menus', {
                headers: {
                    'api-key': tenant.api_key
                }
            }
        ).then(response => {
            setMenus(response.data)
        }).catch(error => {

        });
    }

    const redirectMenu = (menuName, menuId) => {
        history.push({
                pathname: '/menu/' + props.match.params.tenant_hash + '/show/' + menuId,
                state: {
                    menuName: menuName,
                    menuId: menuId
                }
            }
        )
    }

    if (menus && menus.length === 1) {
        history.push({
                pathname: '/menu/' + props.match.params.tenant_hash + '/show/' + menus[0].id,
                state: {
                    menuName: menus[0].name,
                    menuId: menus[0].id
                }
            }
        )
    }

    return (
        <Container className="text-center">
            <h6 className="text-center">Speise- und Weinkarten</h6>
            {menus && menus.length > 1 && menus.map((menu, key) => (
                <Row key={key} className="MenuCol">
                    <Col className="text-center">
                        <Button variant="secondary"
                                onClick={() => redirectMenu(menu.name, menu.id, 'push')}>{menu.name}</Button>
                    </Col>
                </Row>
            ))}
            {(!menus || (menus.length === 0)) && (
                <p>Es sind leider keine Speisekarten hinterlegt.</p>
            )}
        </Container>
    );


}


export default ListMenu;