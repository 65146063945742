import React from 'react'
import {Col, Form} from "react-bootstrap";
import {ErrorMessage, Field, FieldArray} from "formik";
import globalClasses from '../../App.module.css';
import DarkThemeButton from "../UI/Button/DarkThemeButton";
import IconButton from "../UI/Button/IconButton";

const guestFormArray = (props) => {
    return (
        <FieldArray
            name="attendees"
            render={({insert, remove, push}) => (
                <div>
                    {props.values.attendees.length > 0 &&
                    props.values.attendees.map((attendee, index) => (
                        <Form.Row key={index}>
                            <Form.Group as={Col} controlId={`attendees.${index}.first_name`}>
                                <Field type="text" as={Form.Control} //className={styles.InputField}
                                       name={`attendees.${index}.first_name`} placeholder="Vorname"/>
                                <ErrorMessage as={Form.Control.Feedback}
                                              name={`attendees.${index}.first_name`} component="div"
                                              className={globalClasses.ErrorMessage}/>
                            </Form.Group>
                            <Form.Group as={Col} controlId={`attendees.${index}.last_name`}>
                                <Field type="text" as={Form.Control} //className={styles.InputField}
                                       name={`attendees.${index}.last_name`} placeholder="Nachname"/>
                                <ErrorMessage as={Form.Control.Feedback}
                                              name={`attendees.${index}.last_name`} component="div"
                                              className={globalClasses.ErrorMessage}/>
                            </Form.Group>
                            <Col xs={1}>
                                <IconButton variant="light" onClick={() => remove(index)}>x</IconButton>
                            </Col>
                        </Form.Row>
                    ))}
                    <Form.Row>
                        <Form.Group as={Col} className="text-center">
                            <DarkThemeButton
                                onClick={() => push({first_name: "", last_name: ""})}>+ Weiteren
                                Gast hinzufügen</DarkThemeButton>
                        </Form.Group>
                    </Form.Row>
                </div>
            )}
        />
    );
}

export default guestFormArray