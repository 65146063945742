import React, {useEffect, useState} from 'react'
import queryString from 'query-string'
import useAxiosWithJWT from "../../axios/axios-jwt";
import {Redirect} from "react-router";
import useGlobal from "../../store/store";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import promiseAreas from "../../common/constants/promise-areas";
import Spinner from "../../components/UI/Spinner/Spinner";
import log from "../../hoc/Logger";

const ConfirmCheckInScreen = (props) => {

    const [confirmSuccess, setConfirmSuccess] = useState(false);
    // const [confirmRegistration, setConfirmRegistration] = useState(false);
    // const [requestId, setRequestId] = useState(undefined)
    const [checkinExpired, setCheckinExpired] = useState(false);
    // const [code, setCode] = useState(undefined)
    const [skipRegistration] = useState(false);

    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];
    const tenantActions = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    )[1];
    const userActions = useGlobal(
        state => state.user,
        actions => actions.user
    )[1];

    log.debug(skipRegistration, confirmSuccess)

    const checkInLoading = usePromiseTracker({area: promiseAreas.checkin});

    const axios = useAxiosWithJWT();

    useEffect(() => {

        const store = require('store');

        const confirmByAPI = (r, c) => {
            log.debug('confirming', r, c)
            const credentials = {
                request: r,
                opt_in_code: c
            }
            let config = {};

            if (store.get('user_jwt_token') != null) config = {
                headers: {
                    'Authorization': 'Bearer ' + store.get('user_jwt_token')
                }
            }

            //log.debug(credentials)
            trackPromise(
                axios.post('/api/plugin/v1/checkin/confirm', credentials, config)
                    .then(response => {
                        tenantActions.setTenant(response.data.tenant_hash);
                        userActions.setCurrentCheckIn(response.data);
                        if (new Date(response.data.checkin_valid_till).getTime() < new Date().getTime())
                            setCheckinExpired(true)
                        else
                            setConfirmSuccess(true);
                    })
                    .catch(error => {
                        setCheckinExpired(true);
                    }), promiseAreas.checkin);
        }

        if (props.location) {
            const params = queryString.parse(props.location.search);
            log.debug('r', params.r)
            log.debug('c', params.c)

            // setRequestId(params.r);
            // setCode(params.c);

            store.set('current_r', params.r);
            store.set('current_c', params.c);

            if (!checkInLoading.promiseInProgress && !confirmSuccess && !checkinExpired)
                confirmByAPI(params.r, params.c);
        }

    }, [auth, props, axios, checkInLoading.promiseInProgress,
        confirmSuccess, tenantActions, userActions, checkinExpired]);

    if (checkInLoading.promiseInProgress) {
        return <Spinner/>
    }

    if (checkinExpired)
        return (
            <React.Fragment>
                <h5>Check-In abgelaufen</h5>
                <p>Der Check-In ist nicht mehr gültig. Bitte checken Sie erneut ein.</p>
            </React.Fragment>)

    // if (!auth.loggedInUser && !skipRegistration) {
    //     return (
    //         <RegisterAccount
    //             requestId={requestId}
    //             optInCode={code}
    //             skipRegistration={setSkipRegistration}
    //             success={setConfirmRegistration}
    //         />
    //         );
    // } else
    // if (skipRegistration) {
    //     return <Redirect to={'/checkin/current'} />
    // }

    if (confirmSuccess)
        return <Redirect to={'/checkin/current'}/>

    return <div></div>;
}

export default ConfirmCheckInScreen;