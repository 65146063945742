import React from "react";
import * as Yup from "yup";
import {Col, Container, Form} from "react-bootstrap";
import {ErrorMessage, Field, Formik} from "formik";
import axios from "../../axios/Instances/axios-instance";
import globalClasses from "../../App.module.css";
import DarkThemeButton from "../UI/Button/DarkThemeButton";

const initialValues = {
    identifier: ''
};


const validationSchema = Yup.object().shape({
    identifier: Yup.string().required('Benutzername, E-Mail oder Handynummer').matches(new RegExp(`^[0-9_.a-zA-Z@+]*$`), 'Benutzername, E-Mail oder Handynummer')
});

const PasswordResetForm = (props) => {

    let apiError = '';

    if (props.identifier) initialValues.identifier = props.identifier

    return (
        <Container fluid>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnChange
                onSubmit={(values, {setSubmitting, setFieldError}) => {
                    setTimeout(() => {
                        axios.post('/api/account/password/reset/request', {
                                identifier: values.identifier
                            }
                        )
                            .then(response => {
                                //log.debug(response.data)
                                props.success(response.data);
                                setSubmitting(false);
                            })
                            .catch(error => {
                                // log.debug('err'+error.response);
                                apiError = 'Benutzer nicht im System gefunden'
                                setSubmitting(false);
                            });

                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      isInvalid,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="identifier">
                                <Field type="text" as={Form.Control} name="identifier" placeholder="Benutzername"
                                       autoComplete="username"
                                       isInvalid={touched.identifier && errors.identifier}/>
                                <ErrorMessage as={Form.Control.Feedback} name="identifier" component="div"
                                              className={globalClasses.ErrorMessage}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            {apiError && (
                                <Container className="text-center apiError marginBottom">{apiError}</Container>)}
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="text-center">
                                <DarkThemeButton type="submit" variant="secondary"
                                >Passwort zurücksetzen</DarkThemeButton>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </Container>)
};

export default PasswordResetForm;