import React, {useEffect, useState} from 'react';
import Card from "react-bootstrap/Card";
import classes from './AppPreviewAd.module.css';
import moment from 'moment'
import StoreButtons from "./StoreButtons";

const AppPreviewAd = () => {

    const [show, setShow] = useState(classes.fadeIn);
    const [headline, setHeadline] = useState('');

    const closeAd = () => {
        setShow(classes.fadeOut);
    }

    useEffect(() => {
        switch (moment().isoWeek() % 4) {
            case 0:
                setHeadline('Mit der neuen SmartMeeting App mit nur einem Klick einchecken');
                break;
            case 1:
                setHeadline('Mit unserer neuen SmartMeeting App mit nur einem Klick einchecken');
                break;
            case 2:
                setHeadline('Noch schneller einchecken? Holen Sie sich unsere App!');
                break;
            case 3:
                setHeadline('Noch schneller einchecken? Holen Sie sich die neue SmartMeeting App!');
                break;
            default:
                setHeadline('Mit der neuen SmartMeeting App mit nur einem Klick einchecken');
        }
    }, []);

    if (show === classes.fadeOut) return null;

    return (
        <>
            <Card className={classes.AdCard + ' ' + show}>
                <Card.Body className={classes.CardBody}>
                <span style={{float: 'right', cursor: 'pointer', paddingLeft: '1em', fontWeight: 'bold'}}
                      data-effect="fadeOut" onClick={closeAd}>[x]</span>
                    {headline}<br/>
                    <div style={{paddingTop: 10}}><StoreButtons height={30}></StoreButtons></div>

                </Card.Body>
            </Card>
        </>
    );

}

export default AppPreviewAd;
