import React from 'react';
import {ErrorMessage, Field, Formik} from 'formik';
import axios from '../../axios/Instances/axios-instance';
import * as Yup from 'yup';
import {Col, Container, Form} from 'react-bootstrap';
import {
    EMAIL_REGEX,
    MOBILE_PHONE_REGEX,
    REQUIRED_CITY,
    REQUIRED_FIRST_NAME,
    REQUIRED_GUEST_FIRST_NAME,
    REQUIRED_MAIL_OR_MOBILE,
    REQUIRED_MOBILE,
    REQUIRED_NUMERIC_PLZ,
    REQUIRED_PLZ,
    REQUIRED_STREET,
    REQUIRED_STREET_4,
    REQUIRED_VALID_EMAIL,
    REQUIRED_VALID_MOBILE,
    REUQIRED_GUEST_LAST_NAME,
    REUQIRED_LAST_NAME
} from './credentialBasics'
import {Link} from "react-router-dom";
import useGlobal from "../../store/store";
import log from "../../hoc/Logger";
import globalClasses from "../../App.module.css";
import DarkThemeButton from "../UI/Button/DarkThemeButton";
import Checkbox from "../UI/Input/checkbox";

const validationSchema = Yup.object().shape({
    // username: Yup.string().required('Wählen Sie einen Nutzernamen'),
    password: Yup.string().required('Wählen Sie ein Passwort').min(8, 'Mindestens 8 Zeichen'),
    // passwordConfirm: Yup.string()
    //     .oneOf([Yup.ref('password'), null], "Passwörter stimmen nicht überein")
    //     .required('Passwort wiederholen'),
    first_name: Yup.string().required(REQUIRED_FIRST_NAME),
    last_name: Yup.string().required(REUQIRED_LAST_NAME),
    plz: Yup.number().typeError(REQUIRED_NUMERIC_PLZ).required(REQUIRED_PLZ).min(10000, REQUIRED_PLZ).max(999999, REQUIRED_PLZ),
    street: Yup.string().required(REQUIRED_STREET).min(4, REQUIRED_STREET_4),
    city: Yup.string().required(REQUIRED_CITY).min(2, REQUIRED_CITY),

    attendees: Yup.array(Yup.object({
        first_name: Yup.string().required(REQUIRED_GUEST_FIRST_NAME),
        last_name: Yup.string().required(REUQIRED_GUEST_LAST_NAME)
    })),
    //      terms: Yup.boolean().required(REQUIRED_TERMS).oneOf([true],REQUIRED_TERMS),
    //privacy: Yup.boolean().required(REQUIRED_PRIVACY).oneOf([true], REQUIRED_PRIVACY),
    mobile_phone_number: Yup
        .string().default('').when("email_address", {
            is: '',
            then: Yup.string().required(REQUIRED_MOBILE).trim().matches(MOBILE_PHONE_REGEX, REQUIRED_VALID_MOBILE),
            otherwise: Yup.string().when
            ('mobile_phone_number', {
                is: '',
                then: Yup.string(),
                otherwise: Yup.string().trim().matches(MOBILE_PHONE_REGEX, REQUIRED_VALID_MOBILE)
            })
        }),
    email_address: Yup
        .string().default('').when("mobile_phone_number", {
            is: '',
            then: Yup.string().required(REQUIRED_MAIL_OR_MOBILE).trim().matches(EMAIL_REGEX, REQUIRED_VALID_EMAIL),
            otherwise: Yup.string().when
            ('email_address', {
                is: '',
                then: Yup.string(),

                otherwise: Yup.string().trim().matches(EMAIL_REGEX, REQUIRED_VALID_EMAIL)
            })
        }),
}, ["mobile_phone_number", "email_address"])


const RegistrationForm = (props) => {

    const authActions = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[1];

    let initialValues = {
        username: '',
        password: '',
        passwordConfirm: '',
        last_name: '',
        first_name: '',
        mobile_phone_number: "",
        email_address: "",
        street: '',
        plz: '',
        city: '',
    };

    let identifier = '';

    const is_validated_by = () => {
        if (!props.preFilledValues) return ''
        if (props.email_address) return 'email_address'
        else return 'mobile_phone_number'
    }

    if (props.preFilledValues) {

        initialValues.first_name = props.preFilledValues.first_name;
        initialValues.last_name = props.preFilledValues.last_name;
        initialValues.mobile_phone_number = props.preFilledValues.mobile_phone_number;
        initialValues.email_address = props.preFilledValues.e_mail_address;
        initialValues.street = props.preFilledValues.street;
        initialValues.plz = props.preFilledValues.plz.trim();
        initialValues.city = props.preFilledValues.city;
        if (props.preFilledValues.guessed_username)
            initialValues.username = props.preFilledValues.guessed_username
    }

    let apiError = '';

    return (
        <Container fluid>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnMount
                validateOnChange
                onSubmit={(values, {setSubmitting, setFieldError}) => {
                    const json = {
                        username: values.username,
                        plain_password: {
                            first: values.password,
                            second: values.password
                        },
                        first_name: values.first_name,
                        last_name: values.last_name,
                        mobile_phone_number: values.mobile_phone_number,
                        email_address: values.email_address,
                        city: values.city,
                        plz: values.plz,
                        street: values.street,
                        request_id: props.requestId,
                        opt_in_code: props.optInCode,
                        is_validated_by: is_validated_by()
                    };
                    setTimeout(() => {
                        axios.post('/api/plugin/v1/attendee/account/create', json, {})
                            .then(responseOne => {
                                log.debug(responseOne.data)
                                axios.post('/auth/v1/login', {}, {
                                    auth: {
                                        username: values.username,
                                        password: values.password
                                    }
                                })
                                    .then(responseTwo => {
                                        //log.debug(response.data)
                                        authActions.setRefreshToken(responseTwo.data.refresh_token)
                                        authActions.setJwtToken(responseTwo.data.token)
                                        props.success(true);
                                        setSubmitting(false);
                                    })
                                    .catch(error => {
                                        apiError = 'Ihre Zugangsdaten sind ungültig.';
                                        // log.debug('err'+error.response);
                                        setSubmitting(false);
                                    });
                            })
                            .catch(error => {
                                //Object.keys(a).map(function(keyName, keyIndex) {

                                if (error.response.status === 400 && error.response.data.form) {
                                    //log.debug('error')
                                    Object.keys(error.response.data.form.children).map((keyName, keyIndex) => {
                                            if (error.response.data.form.children[keyName].errors)
                                                setFieldError(keyName, error.response.data.form.children[keyName].errors[0])
                                            return null;
                                        }
                                    )
                                }
                                if (error.response.status === 400 && error.response.data.message) {
                                    if (error.response.data.message === 'email') {
                                        apiError = "Es existiert bereits ein Account mit ihrer E-Mail Adresse " + values.email_address + "."
                                        identifier = values.email_address;
                                    }
                                    if (error.response.data.message === 'mobile_phone_number') {
                                        apiError = "Es existiert bereits ein Account mit ihrer Mobilfunknummer " + values.mobile_phone_number + "."
                                        identifier = values.mobile_phone_number;
                                    }
                                    apiError = apiError + " Loggen Sie sich beim nächsten Check-In mit Ihrem Account ein!"
                                }

                                // log.debug('err'+error.response);
                                setSubmitting(false);
                            });

                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      isInvalid,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="username">
                                <Field type="text" as={Form.Control} name="username" placeholder="Benutzername wählen"
                                       autoComplete="username"
                                       isInvalid={touched.username && errors.username}/>
                                <ErrorMessage as={Form.Control.Feedback} name="username" component="div"
                                              className={globalClasses.ErrorMessage}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="password">
                                <Field type="password" as={Form.Control} name="password" autoComplete="new-password"
                                       placeholder="Passwort"
                                       isInvalid={touched.password && errors.password}/>
                                <ErrorMessage as={Form.Control.Feedback} name="password" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row hidden>
                            <Form.Group as={Col} controlId="passwordConfirm">
                                <Field type="password" as={Form.Control} name="passwordConfirm"
                                       autoComplete="new-password" placeholder="Passwort wiederholen"
                                       isInvalid={touched.passwordConfirm && errors.passwordConfirm}/>
                                <ErrorMessage as={Form.Control.Feedback} name="passwordConfirm" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row hidden>
                            <Form.Group as={Col} controlId="first_name">
                                <Field type="text" as={Form.Control} name="first_name" placeholder="Vorname"
                                       isInvalid={touched.first_name && errors.first_name}
                                       autoComplete="given-name"
                                       hidden
                                />
                                <ErrorMessage as={Form.Control.Feedback} name="first_name" component="div"/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="last_name">
                                <Field type="text" as={Form.Control} name="last_name" placeholder="Nachname"
                                       isInvalid={touched.last_name && errors.last_name}
                                       autoComplete="family-name"
                                       hidden/>
                                <ErrorMessage as={Form.Control.Feedback} name="last_name" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row hidden>
                            <Form.Group as={Col} controlId="street">
                                <Field type="text" as={Form.Control} name="street"
                                       placeholder="Straße Hausnummer"
                                       autoComplete="shipping address-line1"
                                       isInvalid={touched.street && errors.street}
                                       hidden/>
                                <ErrorMessage as={Form.Control.Feedback} name="street" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row hidden>
                            <Form.Group as={Col} controlId="plz" xs={4}>
                                <Field type="text" as={Form.Control} name="plz" placeholder="PLZ"
                                       isInvalid={touched.plz && errors.plz}
                                       autoComplete="postal-code"
                                       hidden/>
                                <ErrorMessage as={Form.Control.Feedback} name="plz" component="div"/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="city">
                                <Field type="text" as={Form.Control} name="city" placeholder="Ort"
                                       isInvalid={touched.city && errors.city}
                                       hidden/>
                                <ErrorMessage as={Form.Control.Feedback} name="city" component="div"/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row hidden>
                            <ErrorMessage as={Form.Control.Feedback} name="email_address" component="div"/>
                        </Form.Row>
                        <Form.Row hidden>
                            <Form.Group as={Col} controlId="email_address">
                                <Field type="text" as={Form.Control} name="email_address"
                                       placeholder="E-Mail Adresse"
                                       isInvalid={touched.email_address && errors.email_address}
                                       hidden/>
                            </Form.Group>
                            <Form.Group as={Col} xs={2} className="oderLine" hidden>
                                oder
                            </Form.Group>
                        </Form.Row>
                        <Form.Row hidden>
                            <Form.Group as={Col} controlId="mobile_phone_number">
                                <Field type="text" as={Form.Control} name="mobile_phone_number"
                                       placeholder="Handynummer"
                                       isInvalid={touched.mobile_phone_number && errors.mobile_phone_number}
                                       hidden/>
                                <ErrorMessage as={Form.Control.Feedback} name="mobile_phone_number" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        {/*<Form.Row className="NoMarginBottom">*/}
                        {/*    <Form.Group as={Col} controlId="terms" className="text-center NoMarginBottom">*/}
                        {/*        <MyCheckbox name="terms">Ich stimme den AGB zu</MyCheckbox>*/}
                        {/*    </Form.Group>*/}
                        {/*</Form.Row>*/}
                        {!props.preFilledValues && (<Form.Row>
                            <Form.Group as={Col} controlId="privacy">
                                <Checkbox name="privacy">Ich stimme den <Link
                                    to="privacy">Datenschutzbedingungen</Link> zu</Checkbox>
                            </Form.Group>
                        </Form.Row>)}
                        <Form.Row className="text-center">
                            <Container
                                className="text-center apiError marginBottom">{apiError}</Container>
                            {apiError !== '' && (
                                <Container className="text-center marginBottom">
                                    <Link className="ImportantLink" to={{
                                        pathname: '/login',
                                        state: {
                                            identifier: identifier
                                        }
                                    }}>Hier einloggen</Link>
                                </Container>
                            )}
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="text-center">
                                <DarkThemeButton type="submit" variant="secondary"
                                                 disabled={(!isValid) || isSubmitting}>Account
                                    erstellen</DarkThemeButton>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )
                }
            </Formik>
        </Container>)
};

export default RegistrationForm;