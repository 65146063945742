import React from 'react';
import {Route, Switch} from 'react-router-dom';
import routes from '../../router/routes';
import AppHeader from "../Navigation/Header/Header";
import AppPreviewAd from "../UI/Ads/AppPreviewAd";
import {useLocation} from "react-router";
import FlexFill from './FlexFill'
import classes from './Layout.module.css';

const Layout = () => {

    const location = useLocation();
    const adExceptions = ['/privacy', '/app', '/terms', '/privacy-may', '/checkin/current', '/account/register', '/impressum'];
    const menuExceptions = ['/privacy', '/app', '/terms', '/privacy-may', '/impressum'];

    return (
        <FlexFill>
            <AppHeader showMenu={!menuExceptions.includes(location.pathname)}/>
            <FlexFill className={[classes.CenterLayout, classes.MaxWidth600, 'overflow-hidden'].join(' ')}>
                <FlexFill className={'container overflow-auto'}>
                    {!adExceptions.includes(location.pathname) && <AppPreviewAd/>}
                    <Switch>
                        {routes.map((route, i) => (
                            <Route key={i} exact {...route} />
                        ))}
                    </Switch>
                </FlexFill>
                {/*<AppFooter/>*/}
            </FlexFill>
        </FlexFill>
    );

}

export default Layout;
