import React from "react";
import HTMLReactParser from "html-react-parser/index";

const SecureHTMLText = (props) => {

    const createDOMPurify = require('dompurify');
    const purify = createDOMPurify(window);

    return (<>{HTMLReactParser(purify.sanitize(props.htmlInput, {ADD_ATTR: ['target']}))}</>);

}

export default SecureHTMLText;