import React from 'react'
import LoginForm from "../../components/Forms/Auth/LoginForm";
import {Redirect, withRouter} from "react-router";
import {Container} from "react-bootstrap";
import useGlobal from "../../store/store";

const Login = (props) => {

        const [auth, authActions] = useGlobal(
            state => state.auth,
            actions => actions.auth
        );

        const returnTo = props.location.state ? props.location.state.returnTo : null;

        const setSuccessTrue = (data) => {
            authActions.setRefreshToken(data.refresh_token);
            authActions.setJwtToken(data.token);
        }

        if (auth.loggedInUser != null) {
            if (returnTo)
                return <Redirect to={returnTo}></Redirect>;
            else
                return <Redirect to={'/'}></Redirect>;
        }


        return (
            <Container className="text-center">
                <h6>Mit Ihren Benutzerdaten einloggen</h6><br/>
                <LoginForm identifier={props.location.state ? props.location.state.identifier : ''}
                           success={setSuccessTrue}
                />
            </Container>
        );

    }
;


export default withRouter(Login);