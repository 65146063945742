import React, {useEffect, useState} from "react";
import Logo from "../UI/Logo/Logo";
import classes from './BoxedLayout.module.css';
import FlexFill from './FlexFill'


const BoxedLayout = (props) => {

    const [colorClass, setColorClass] = useState(classes.Inactive);

    useEffect(() => {
        if (props.success === true) setColorClass(classes.Success);
    }, [props.success])

    return (
        <FlexFill className={classes.BoxContainer}>
            <Logo logoUrl={props.logoUrl}/>
            <FlexFill className={''} height={'auto'}>
                <FlexFill className={[classes.Box, colorClass].join(' ')} height={'auto'}>
                    {props.children}
                </FlexFill>
            </FlexFill>
        </FlexFill>
    );
}

export default BoxedLayout;
