import React from 'react';

import classes from './Logo.module.css';
import {Container, Image, Row} from 'react-bootstrap';

const logo = (props) => {

    return (
        <Container className={classes.ImageContainer}>
            <Row>
                <div className={classes.ImageContainer}>
                    {props.logoUrl ? <Image src={'https://api.smartmeeting.online'+ props.logoUrl} className={classes.Logo} alt="Logo" fluid/> : null}
                </div>
            </Row>
        </Container>
    );
};

export default logo;