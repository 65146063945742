import React, {useEffect, useState} from "react";
import axios from "../../axios/Instances/axios-instance";
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router";
import {Document, Page, pdfjs} from "react-pdf";
import './showMenu.css'
import useGlobal from "../../store/store";
import {usePromiseTracker} from "react-promise-tracker";
import promiseAreas from "../../common/constants/promise-areas";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ShowMenu = (props) => {

    const menuId = props.match.params.menu_id;
    const [menu, setMenu] = useState(null)
    const history = useHistory();
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    );

    const tenantLoading = usePromiseTracker({area: promiseAreas.tenant})

    if (!tenant.api_key && !tenantLoading.promiseInProgress ) {
        tenantActions.setTenant(props.match.params.tenant_hash)
    }

    //log('test');

    useEffect(() => {
        if (menuId && tenant.api_key && !menu) {
            axios.get('/api/plugin/v1/menu/' + menuId, {
                    headers: {
                        'api-key': tenant.api_key
                    }
                }
            ).then(response => {
                setMenu(response.data)
                //log.debug(response.data)

            }).catch(error => {

            });
        }
        ;
    }, [tenant.api_key, menu, menuId])

    if (!menuId && !menu) return (<h6>Keine Karte gefunden</h6>)

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    }

    //if (menu) log.debug('https://api.smartmeeting.online' + menu[0].image.file_url);
    return (
        <Container className="text-center">
            {menu && (
                <Container className="text-center">

                    <h3 className="MenuHeading">{menu.name}</h3>
                    <Row>
                        <Col className="text-center">
                            <Document className={"PDFDocument"}
                                      file={{url: "https://api.smartmeeting.online" + menu[0].image.file_url}}
                                      onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page className={"PDFPage"} pageNumber={pageNumber}/>
                            </Document>

                            <p>
                                Seite {pageNumber || (numPages ? 1 : '--')} von {numPages || '--'}
                            </p>

                            <Row>
                                <Col>
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}
                                    >
                                        {"<"}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}
                                    >
                                        {">"}
                                    </Button>

                                </Col>
                            </Row>


                        </Col>
                    </Row></Container>
            )}
            <p></p>
            <Container>
                <Button variant="secondary" onClick={history.goBack}>Zurück</Button>
            </Container>
        </Container>
    );

}

export default ShowMenu
