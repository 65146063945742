import React from 'react';
import {ErrorMessage, Field, Formik} from 'formik';
import * as Yup from 'yup';
import {Col, Container, Form} from 'react-bootstrap';
import {
    REQUIRED_CITY,
    REQUIRED_NUMERIC_PLZ,
    REQUIRED_PLZ,
    REQUIRED_PRIVACY,
    REQUIRED_STREET,
    REQUIRED_STREET_4,
} from './credentialBasics'
import useAxiosWithJWT from "../../axios/axios-jwt";
import useGlobal from "../../store/store";
import globalClasses from "../../App.module.css";
import DarkThemeButton from "../UI/Button/DarkThemeButton";
import Checkbox from "../UI/Input/checkbox";

const validationSchema = Yup.object().shape({
    plz: Yup.number().typeError(REQUIRED_NUMERIC_PLZ).required(REQUIRED_PLZ).min(10000, REQUIRED_PLZ).max(999999, REQUIRED_PLZ),
    street: Yup.string().required(REQUIRED_STREET).min(4, REQUIRED_STREET_4),
    city: Yup.string().required(REQUIRED_CITY).min(2, REQUIRED_CITY),
    privacy: Yup.boolean().required(REQUIRED_PRIVACY).oneOf([true], REQUIRED_PRIVACY),
},)

const AddressForm = (props) => {

    const axios = useAxiosWithJWT();
    const [auth, authActions] = useGlobal(
        state => state.auth,
        actions => actions.auth
    );

    let initialValues = {
        street: '',
        plz: '',
        city: '',
    };

    if (props.preFilledValues) {
        initialValues.street = props.preFilledValues.address_street;
        initialValues.plz = props.preFilledValues.address_plz;
        initialValues.city = props.preFilledValues.address_city;
    }

    //log.debug(props.preFilledValues);

    return (
        <Container fluid>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                validateOnMount
                validateOnChange
                onSubmit={(values, {setSubmitting, setFieldError}) => {
                    const json = {
                        address_city: values.city,
                        address_plz: values.plz,
                        address_street: values.street,
                        check_in_app_privacy: values.privacy
                    };

                    setTimeout(() => {
                        axios.patch('/api/plugin/v1/account', json, {
                            headers: {
                                'Authorization': 'Bearer ' + auth.jwtToken
                            }
                        })
                            .then(response => {
                                authActions.setTenant(response.data)
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });

                    }, 400);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      isInvalid,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="street">
                                <Field type="text" as={Form.Control} name="street"
                                       placeholder="Straße Hausnummer"
                                       autoComplete="shipping address-line1"
                                       isInvalid={touched.street && errors.street}/>
                                <ErrorMessage as={Form.Control.Feedback} name="street" component="div"
                                              className={globalClasses.ErrorMessage}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="plz" xs={4}>
                                <Field type="text" as={Form.Control} name="plz" placeholder="PLZ"
                                       isInvalid={touched.plz && errors.plz}
                                       autoComplete="postal-code"/>
                                <ErrorMessage as={Form.Control.Feedback} name="plz" component="div"/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="city">
                                <Field type="text" as={Form.Control} name="city" placeholder="Ort"
                                       isInvalid={touched.city && errors.city}/>
                                <ErrorMessage as={Form.Control.Feedback} name="city" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="privacy">
                                <Checkbox name="privacy">Ich stimme den <a
                                    href="privacy">Datenschutzbedingungen</a> zu</Checkbox>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="text-center">
                                <DarkThemeButton type="submit" variant="secondary"
                                                 disabled={(!isValid) || isSubmitting}>Daten
                                    hinterlegen</DarkThemeButton>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )
                }
            </Formik>
        </Container>)
};

export default AddressForm;