import React, {Component} from 'react';
import Layout from './components/Layout/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.module.css';
import './index.css';
import './main.css';

class App extends Component {

    render() {
        return (
            <Layout/>
        );
    }
}

export default App;
