import React from "react";
import FlexFill from "./FlexFill";
import BoxedLayout from "./BoxedLayout";
import AdArea from "../UI/Ads/AdArea";

const ScreenWithAdArea = ({logoUrl, children, adType, success}) => {
    if (!success) success = false;
    return (
        <FlexFill className={'overflow-hidden'}>
            <FlexFill className={'overflow-auto'}>
                <BoxedLayout success={success} logoUrl={logoUrl}>
                    {children}
                </BoxedLayout>
            </FlexFill>
            <AdArea type={adType}/>
        </FlexFill>
    );
}

export default ScreenWithAdArea;

