import React from "react";
import Button from "react-bootstrap/Button";
import style from './DarkThemeButton.module.css';

const DarkThemeButton = (props) => {

    return <Button variant={"secondary"} {...props} className={style.DarkButton}>{props.children}</Button>

}

export default DarkThemeButton;