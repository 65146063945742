import {trackPromise} from "react-promise-tracker";
import promiseAreas from "../common/constants/promise-areas";
import axiosInstance from "../axios/Instances/axios-instance";
import {setCurrentCheckIn} from "./user-actions";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import log from "../hoc/Logger";

const localStore = require('store');

export const setJwtToken = (store, token, dontReloadUser = false) => {
    log.debug('Setting new jwt');
    if (!dontReloadUser)
        loadLoggedInUser(store, token);
    localStore.remove('current_r');
    localStore.remove('current_c');
    const newAuth = {...store.state.auth};
    newAuth.jwtToken = token;
    store.setState({auth: newAuth});
};

export const setRefreshToken = (store, token) => {
    log.debug('setting new refresh token', token);
    localStore.set('user_refresh_token', token);
    const newAuth = {...store.state.auth};
    newAuth.refreshToken = token;
    store.setState({auth: newAuth});
    log.debug('refresh token set')
};

export const setLoggedInUser = (store, user) => {
    log.debug('setting loggedInUser', user);
    const newAuth = {...store.state.auth};
    newAuth.loggedInUser = user;
    store.setState({auth: newAuth});
};

const loadLoggedInUser = (store, jwtToken) => {
    log.debug('[loadLoggedInUser] loading user for token', jwtToken);
    log.debug(store.state.auth);

    const refreshAuthLogic = failedRequest => axiosInstance.post('/auth/v2/refresh', null, {withCredentials: true}).then(tokenRefreshResponse => {
        log.debug('repsonse jwt token', tokenRefreshResponse.data.token);
        setJwtToken(store, tokenRefreshResponse.data.token, true);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
        return Promise.resolve();
    });

    createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);

    log.debug(axiosInstance.interceptors);

    trackPromise(
        axiosInstance.get('/api/plugin/v1/account', {
            headers: {
                'Authorization': 'Bearer ' + jwtToken
            }
        }).then(response => {
                setLoggedInUser(store, response.data)
                log.debug('[loadLoggedInUser] loading current checkins')
                trackPromise(
                    axiosInstance.get('/api/plugin/v1/account/checkins/current', {
                        headers: {
                            'Authorization': 'Bearer ' + store.state.auth.jwtToken
                        }
                    }).then(response => {
                            setCurrentCheckIn(store, response.data)
                        }
                    ).catch(error => {
                        log.debug('[loadLoggedInUser] error current checkins')
                        //logOut(store);
                    }), promiseAreas.auth);
            }
        ).catch(error => {
            log.debug('error getting user for token', error)
            logOut(store);
        })
        , promiseAreas.auth);


}

export const logOut = (store, test) => {
    log.debug('logging out current user');

    axiosInstance.post('/auth/v2/logout', null, {withCredentials: true}).then(tokenRefreshResponse => {
            log.debug('logout triggered');
        }
    );

    localStore.remove('user_jwt_token');
    localStore.remove('user_refresh_token');
    localStore.remove('user_current_checkin');
    store.setState({
        auth: {
            jwtToken: null,
            refreshToken: null,
            loggedInUser: null,
        },
        user: {
            currentCheckIn: null
        }
    });
}