import React from 'react';
import {Container} from "react-bootstrap";
import {IconContext} from "react-icons";
import {FaEnvelope, FaMobileAlt} from "react-icons/fa/index";
import log from "../../hoc/Logger";

const CheckinFormSubmittedScreen = (props) => {

    const sentBy = props.location.state.responseData;
    log.debug('[CheckinFormSubmittedScreen] sentBy:', sentBy)
    let sentByText = '';

    switch (sentBy) {
        case 'mail':
            sentByText = 'E-Mail'
            break
        case 'sms':
            sentByText = 'SMS'
            break
        default:
            sentByText = 'E-Mail'
    }

    return (
        <Container className="text-center">
            <h5>Bestätigung ausstehend</h5>
            <p>Vielen Dank, Sie erhalten in Kürze eine {sentByText} mit dem
                Bestätigungslink.</p>
            <p>
                <IconContext.Provider
                    value={{color: '#00a0de', size: '20%', paddingTop: "10px", paddingBottom: "10px"}}>
                    {(sentBy === 'sms') ? <FaMobileAlt/> : <FaEnvelope/>}
                </IconContext.Provider>
            </p>
            <p><strong>Ihre Registrierung ist erst nach Klick auf den Link abgeschlossen.</strong></p>
        </Container>
    );

}

export default CheckinFormSubmittedScreen;