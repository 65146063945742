import log from "../hoc/Logger";
import cookie from 'react-cookies';

const localStore = require('store');

export const setCurrentCheckIn = (store, currentCheckin, r = null, c = null) => {
    log.debug('Setting new current checkin', currentCheckin);
    const newUser = {...store.state.user};
    if (!currentCheckin || currentCheckin === 'null'
        || (currentCheckin && currentCheckin.checkin_valid_till
            && new Date(currentCheckin.checkin_valid_till).getTime() < new Date().getTime())) {
        log.debug('removing current checkin')
        localStore.remove('user_current_checkin');
        localStore.remove('current_r');
        localStore.remove('current_c');
        cookie.remove('current_checkin', {path: '/'});
        newUser.currentCheckIn = null;
    } else {
        log.debug('saving current checkin');
        if (r && c) {
            currentCheckin.request_id = r;
            currentCheckin.opt_in_code = c;
        }
        if (currentCheckin.request_id && currentCheckin.opt_in_code) {
            localStore.set('current_r', currentCheckin.request_id);
            localStore.set('current_c', currentCheckin.opt_in_code);
        }
        localStore.set('user_current_checkin', currentCheckin);
        cookie.save('current_checkin', currentCheckin, {path: '/'});
        newUser.currentCheckIn = {...currentCheckin};
    }
    store.setState({user: newUser});
    log.debug(store.state.user);
    // }
};
