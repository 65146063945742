import React, {useEffect, useState} from "react";
import queryString from "query-string";
import axios from "../../axios/Instances/axios-instance";

const ConfirmEmail = (props) => {

    const [result, setResult] = useState('');

    const confirmationToken = (props.location.search) ? queryString.parse(props.location.search).c : null;

    const email = (props.location.search) ? queryString.parse(props.location.search).e : null;

    useEffect(() => {

        const json = {
            "email": email,
            "confirmation_token": confirmationToken
        }

        axios.post("api/plugin/v1/account/email/enable", json).then((response) => {
                if (response.status === 200)
                    setResult('SUCCESS');
            }
        ).catch((error) => {
            if (error.response.data.message.includes('bereits bestätigt'))
                setResult('IS_ALREADY_ACTIVE');
            if (error.response.data.message.includes('falsch'))
                setResult('WRONG_CODE');
        });
    }, [confirmationToken, email]);

    if (result === '') return <div></div>;
    if (result === 'SUCCESS') return <div className="text-center">Vielen Dank, Ihre E-Mail Adresse wurde
        bestätigt.<br/><a href={"smcia17092020://smartmeeting.online/login"}>Zurück zur App</a></div>
    if (result === 'IS_ALREADY_ACTIVE') return <div className="text-center">Vielen Dank, Ihre E-Mail Adresse wurde
        bereits bestätigt. <br/><a href={"smcia17092020://smartmeeting.online/login"}>Zurück zur App</a></div>
    if (result === 'WRONG_CODE') return <div className="text-center">Der Bestätigungscode ist falsch.</div>

}

export default ConfirmEmail;
