import React, {useEffect, useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Col, Container, Form} from 'react-bootstrap';
import GuestFormArray from "./guestsFormArray";
import useAxiosWithJWT from "../../axios/axios-jwt";
import useGlobal from "../../store/store";
import './credentialBasics';
import {NAME_REGEX} from "./credentialBasics";
import getAdditionalFormElements, {
    prepareAdditionalFormData,
    updateInitialValuesAndValidation
} from "../../services/AdditionalFormService";
import log from "../../hoc/Logger";
import DarkThemeButton from "../UI/Button/DarkThemeButton";
import {useMounted} from '../../hooks/Mounted'

var _ = require('lodash');

const REQUIRED_GUEST_FIRST_NAME = 'Bitte geben Sie den Vornamen des Gastes ein';
const REUQIRED_GUEST_LAST_NAME = 'Bitte geben Sie den Nachnamen des Gastes ein';


const GuestCredentialsForm = (props) => {

    const mounted = useMounted();

    const form = useRef();
    const [validationSchema, setValidationSchema] = useState(Yup.object().shape({
        attendees: Yup.array(Yup.object({
            first_name: Yup.string().required(REQUIRED_GUEST_FIRST_NAME).matches(NAME_REGEX, REQUIRED_GUEST_FIRST_NAME),
            last_name: Yup.string().required(REUQIRED_GUEST_LAST_NAME).matches(NAME_REGEX, REUQIRED_GUEST_LAST_NAME)
        }))
    }));

    const [initialValues] = useState({
        attendees: [],
        //  terms: false
    });

    const globalState = useGlobal(
        state => state,
        actions => actions
    )[0];

    const userActions = useGlobal(
        state => state.user,
        actions => actions.user
    )[1];

    const tenant = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    )[0];

    const [additionalFormProcessed, setAdditionalFormProcessed] = useState(false);


    useEffect(() => {

        if (tenant.additional_form && !additionalFormProcessed && form.current) {
            log.debug('here');
            updateInitialValuesAndValidation(tenant.additional_form, form, validationSchema, setValidationSchema);
            log.debug(validationSchema);
            setAdditionalFormProcessed(true);

        }
    }, [tenant, form, additionalFormProcessed, validationSchema]);


    const headers = {
        'api-key': globalState.tenant.api_key,
        'Authorization': 'Bearer ' + globalState.auth.jwtToken
    }

    let apiError = '';

    const axios = useAxiosWithJWT();

    return (
        <Container>
            {!tenant.additional_form &&
            <p className="text-center">Möchten Sie nur als {globalState.auth.loggedInUser.display_name} einchecken, oder
                weitere Gäste hinzufügen?</p>}
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.lazy(values => validationSchema)}
                enableReinitialize
                validateOnChange={true}
                onSubmit={(values, {setSubmitting, setFieldError}) => {
                    log.debug('validation schema on submit', validationSchema);
                    const attendeeList = values.attendees;
                    const initiator = _.cloneDeep(values);
                    _.unset(initiator, 'attendees');
                    setTimeout(() => {

                        const checkInRequest = {
                            terms: 1,
                            privacy: 1,
                            resource: props.resource,
                            callback_url: process.env.REACT_APP_REDIRECT_URL,
                            initiator: {
                                smartmeeting_user: props.userId,
                                jwt_token: globalState.auth.jwtToken
                            },
                            attendees: attendeeList
                        }
                        if (tenant.additional_form != null) {
                            checkInRequest[tenant.additional_form.identifier] = prepareAdditionalFormData(initiator, tenant);
                        }


                        axios.post('/api/plugin/v1/checkin/identified', checkInRequest, {
                            headers: headers
                        })
                            .then(response => {
                                console.log('is mounted', mounted.current);
                                userActions.setCurrentCheckIn(response.data);
                                props.success(true);
                                setSubmitting(false);
                            })
                            .catch(error => {
                                apiError = 'Es ist ein Fehler aufgetreten.'
                                setSubmitting(false);
                            });

                    }, 400);
                }}
                innerRef={form}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      isInvalid,
                      dirty
                  }) => (

                    <Form onSubmit={handleSubmit}
                    >
                        <Container className="text-center marginBottom">
                            <h6>{props.resourceName}</h6>
                        </Container>
                        <Form.Row className="text-center">
                            <p className="text-center apiError">{apiError}</p>
                        </Form.Row>

                        {!tenant.additional_form && <GuestFormArray values={values}/>}

                        {tenant.additional_form && form.current && getAdditionalFormElements(validationSchema, tenant.additional_form, form,
                            setAdditionalFormProcessed, setValidationSchema)}

                        <Form.Row>
                            <Form.Group as={Col} className="text-center">
                                <DarkThemeButton type="submit" variant="secondary"
                                                 disabled={isSubmitting}
                                >Check
                                    In</DarkThemeButton>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </Container>)
};

export default GuestCredentialsForm;
