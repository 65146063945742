import React from 'react';
import {ErrorMessage, Field, Formik} from 'formik';
import axios from "../../axios/Instances/axios-instance";
import * as Yup from 'yup';
import {Button, Col, Container, Form} from 'react-bootstrap';
import areas from '../../common/constants/promise-areas';
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import Spinner from '../UI/Spinner/Spinner';

const initialValues = {
    password: '',
    passwordRepeat: ''
};


const validationSchema = Yup.object().shape({
    password: Yup.string().required('Wählen Sie ein Passwort').min(8, 'Mindestens 8 Zeichen'),
    passwordRepeat: Yup.string().required('Passwort bestätigen')
        .test('passwords-match', 'Passwörter stimmen nicht überein', function (value) {
            return this.parent.password === value;
        }),
});

const ResetPasswordForm = (props) => {

    const {promiseInProgress} = usePromiseTracker({area: areas.auth, delay: 500});

    return (
        <Container fluid>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, {setSubmitting, setFieldError}) => {
                    const json = {

                        password: {
                            first: values.password,
                            second: values.passwordRepeat
                        },
                    };
                    trackPromise(
                        axios.post('/api/account/password/reset/' + props.confirmationToken, json, {})
                            .then(response => {
                                props.success();
                            })
                            .catch(error => {
                                setSubmitting(false);
                            })


                        , areas.auth);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      isInvalid,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="password">
                                <Field type="password" as={Form.Control} name="password" autoComplete="current-password"
                                       placeholder="Passwort"
                                       isInvalid={touched.password && errors.password}/>
                                <ErrorMessage as={Form.Control.Feedback} name="password" component="div"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="passwordRepeat">
                                <Field type="password" as={Form.Control} name="passwordRepeat"
                                       autoComplete="current-password"
                                       placeholder="Passwort wiederholen"
                                       isInvalid={touched.passwordRepeat && errors.passwordRepeat}/>
                                <ErrorMessage as={Form.Control.Feedback} name="passwordRepeat" component="div"/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} className="text-center">
                                {promiseInProgress ? <Spinner/> :
                                    <Button type="submit" variant="secondary"
                                            disabled={(!isValid || !dirty) || isSubmitting}>Neues Passwort
                                        setzen</Button>}
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </Container>)
};

export default ResetPasswordForm;