import React, {useEffect, useState} from 'react';

import useGlobal from "../../store/store";
import {Redirect, useHistory, withRouter} from "react-router";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import areas from "../../common/constants/promise-areas";
import promiseAreas from "../../common/constants/promise-areas";
import queryString from "query-string";
import axios from "../../axios/Instances/axios-instance";
import Spinner from "../../components/UI/Spinner/Spinner";
import {Container} from "react-bootstrap";
import AddressForm from "../../components/Forms/addressForm";
import GuestCredentialsForm from "../../components/Forms/guestCredentialsForm";
import CredentialForm from "../../components/Forms/credentialForm";
import log from "../../hoc/Logger";
import ScreenNoAdArea from "../../components/Layout/ScreenNoAdArea";

const CheckInScreen = (props) => {

    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];

    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    );

    const [user, userActions] = useGlobal(
        state => state.user,
        actions => actions.user
    );

    const loadingTenant = usePromiseTracker({area: promiseAreas.tenant});
    const [resourceName, setResourceName] = useState(null);
    const tenantChanging = usePromiseTracker({area: areas.auth, delay: 500});
    const history = useHistory();


    let resource = (props.location.search) ? queryString.parse(props.location.search).table ? queryString.parse(props.location.search).table : queryString.parse(props.location.search).resource : null;

    const processUnauthorizedCheckIn = (responseData) => {
        if (!responseData.checkin_valid_for) history.push({
            pathname: '/checkin/submitted',
            state: {responseData}
        })
        else {
            userActions.setCurrentCheckIn(responseData);
            history.push('/checkin/current')
        }
    }

    useEffect(() => {
        if (resource && !resourceName && tenant.api_key) {
            log.debug('loading resource name')
            trackPromise(
                axios.get('/api/plugin/v1/resource/' + resource, {
                        headers: {
                            'api-key': tenant.api_key
                        }
                    }
                ).then(response => {
                        setResourceName(response.data.name);
                    }
                ).catch(error => {
                    }
                ), promiseAreas.tenant);
        }

        if (!tenantChanging.promiseInProgress)
            tenantActions.setTenant(props.match.params.tenant_hash);

    }, [props.match.params.tenant_hash, resource, resourceName, tenant.api_key, tenantActions, tenantChanging.promiseInProgress])

    log.debug('[Switch if checked in]', user.currentCheckIn, props)
    if (user.currentCheckIn
        && user.currentCheckIn.tenant_hash === props.match.params.tenant_hash
        && (user.currentCheckIn.resource_id === resource || (!resource && !user.currentCheckIn.resource_id))) {
        // && globalState.user.currentCheckIn.tenant_hash == props.match.params.tenant_hash) {

        return (
            <Redirect to={'/checkin/current'}/>
        );
    }

    if (loadingTenant.promiseInProgress) return (<Spinner/>);

    if (tenant.tenant_hash === null) return <div></div>;

    let checkInBody = (
        <Container>
            <CredentialForm tenant={tenant}
                            resource={resource}
                            resourceName={resourceName}
                            success={processUnauthorizedCheckIn}/>
        </Container>);

    if (auth.loggedInUser &&
        (!auth.loggedInUser.address_plz || !auth.loggedInUser.address_street || !auth.loggedInUser.address_city)
    )
        checkInBody = (
            <div>
                <p>Bitte hinterlegen Sie Ihre aktuelle Adresse.</p>
                <AddressForm preFilledValues={props.loggedInUser}/>
            </div>);

    if (auth.loggedInUser)
        checkInBody = (
            <Container>
                <GuestCredentialsForm
                    resource={resource}
                    resourceName={resourceName}
                    userId={auth.loggedInUser.id}
                    success={() => {
                        props.history.push('/checkin/current');
                    }}/>
            </Container>);

    return (
        <ScreenNoAdArea logoUrl={tenant.logo_url} adType={'bottom_ad_checkin'}>
            {checkInBody}
        </ScreenNoAdArea>
    );

}

export default withRouter(CheckInScreen);
