import CheckInScreen from "../containers/CheckInScreen/CheckInScreen";
import ConfirmCheckInScreen from '../containers/CheckInScreen/ConfirmCheckInScreen';
import Login from '../containers/Auth/Login';
import ShowMenu from "../containers/Menu/ShowMenu";
import PrivacyScreen from "../containers/Legal/PrivacyScreen";
import ImpressumScreen from "../containers/Legal/ImpressumScreen";
import PasswordReset from "../containers/Auth/PasswordReset";
import ShowPartnerScreen from "../containers/PartnerScreen/ShowPartnerScreen";
import AdminMenusScreen from "../containers/Admin/AdminMenusScreen";
import AdminHomepageScreen from "../containers/Admin/AdminHomepageScreen";
import Homepage from "../containers/Homepage/Homepage";
import ShowCurrentCheckInScreen from "../containers/CheckInScreen/ShowCurrentCheckInScreen";
import CheckinFormSubmittedScreen from "../containers/CheckInScreen/CheckInFormSubmittedScreen";
import ListMenu from "../containers/Menu/ListMenu";
import ShowTenantCheckinOverview from "../containers/Admin/ShowTenantCheckinOverview";
import UploadNewMenu from "../containers/Admin/UploadNewMenu";
import CheckOutScreen from "../containers/CheckInScreen/CheckOutScreen";
import ConfirmAccount from "../containers/Service/ConfirmAccount";
import ResetPassword from "../containers/Account/ResetPassword";
import ConfirmMobile from "../containers/Service/ConfirmMobile";
import ConfirmEmail from "../containers/Service/ConfirmEmail";
import AppDownload from "../containers/Static/AppDownload";
import TermsScreen from "../containers/Legal/TermsScreen";
import ChooseDataDate from "../containers/Admin/ChooseDataDate";
import TermsScreenMay from "../containers/Legal/TermsScreenMay";
import RegisterAccount from "../containers/Auth/RegisterAccount";
import PrivacyScreenAugust from "../containers/Legal/PrivacyScreenAugust";

const routes = [

    {
        path: "/admin/:tenant_hash/menu/:menu_id",
        component: UploadNewMenu
    },
    {
        path: "/admin/:tenant_hash/checkins",
        component: ShowTenantCheckinOverview
    },
    {
        path: "/menu/:tenant_hash/show/:menu_id",
        component: ShowMenu
    },
    {
        path: "/menu/:tenant_hash",
        component: ListMenu
    },
    {
        path: "/admin/:tenant_hash/menus",
        component: AdminMenusScreen
    },
    {
        path: "/admin/:tenant_hash",
        component: AdminHomepageScreen
    },
    {
        path: "/admin/:tenant_hash/checkin-data/choosedate",
        component: ChooseDataDate
    },
    {
        path: "/partner",
        component: ShowPartnerScreen
    },
    {
        path: "/checkin/confirm",
        component: ConfirmCheckInScreen
    },
    {
        path: "/checkin/submitted",
        component: CheckinFormSubmittedScreen
    },
    {
        path: "/checkin/current",
        component: ShowCurrentCheckInScreen
    },
    {
        path: "/checkout/tenant/:tenant_hash",
        component: CheckOutScreen
    },
    {
        path: "/checkin/tenant/:tenant_hash",
        component: CheckInScreen
    },
    {
        path: "/login",
        component: Login
    },
    {
        path: "/privacy-august",
        component: PrivacyScreenAugust
    },
    {
        path: "/privacy",
        component: PrivacyScreen
    },
    {
        path: "/terms",
        component: TermsScreen
    },
    {
        path: "/terms-may",
        component: TermsScreenMay
    },
    {
        path: "/account/app/confirm",
        component: ConfirmAccount
    },
    {
        path: "/account/app/mobile/confirm",
        component: ConfirmMobile
    },
    {
        path: "/account/app/email/confirm",
        component: ConfirmEmail
    },
    {
        path: "/account/password/reset",
        component: ResetPassword,
    },
    {
        path: "/account/register",
        component: RegisterAccount,
    },
    {
        path: "/impressum",
        component: ImpressumScreen
    },
    {
        path: "/",
        component: Homepage
    }, {
        path: "/resetpwd",
        component: PasswordReset
    },
    {
        path: "/app",
        component: AppDownload
    }
];

export default routes;