import React from "react";
import {Container} from "react-bootstrap";
import './PrivacyScreen.css';
import {Link} from "react-router-dom";

const PrivacyScreen = () => {

    return (<Container>
        <h5>Datenschutzhinweise für das SmartMeeting Corona-Tracing gemäß Datenschutz-Grundverordnung („DS-GVO“)</h5>

        <p>Stand: 15. September 2021</p>
        <p>Mit den nachfolgenden Informationen geben wir Ihnen einen Überblick über die Verarbeitung Ihrer
            personenbezogenen Daten durch uns und Ihre Rechte gemäß datenschutzrechtlicher Bestimmungen im Zusammenhang
            mit der Nutzung unseres Dienstes SmartMeeting Kontakte. </p>
        <p><i>SmartMeeting Kontakte ist ein Dienst für die Ermöglichung einer schnellen Nachverfolgung von Infektionen
            mit dem neuen Coronavirus (SARS-CoV-2) durch die zuständigen Gesundheitsämter. Für diesen Zweck werden Ihre
            Kontakt- und Besuchsdaten digital erfasst und gespeichert sowie für die zuständigen Behörden für die Dauer
            von einem Monat vorgehalten und auf Anforderung an diese übermittelt. Nach Ablauf dieser Frist werden
            sämtliche Daten unverzüglich gelöscht. Die datenschutzrechtlichen Bestimmungen werden dabei
            selbstverständlich eingehalten.</i></p>
        <h5>Inhalt</h5>
        <ul className="ListNumbered">
            <li>Name und Kontaktdaten der verantwortlichen Stelle</li>
            <li>Erhebung und Speicherung personenbezogener Daten sowie Art, Zweck und Verwendung</li>
            <li>Weitergabe von Daten an Dritte</li>
            <li>Ihre Rechte als betroffene Person</li>
            <li>Ihr Recht auf Widerspruch</li>
            <li>Datensicherheit</li>
            <li>Speicherdauer</li>
        </ul>

        <h5>1. Name und Kontaktdaten der verantwortlichen Stelle</h5>
        <p>Verantwortliche Stelle nach Artikel 4 Nr. 7 DS-GVO ist die:</p>
        <p>COSYNUS GmbH<br/>
            Herr Michael Reibold<br/>
            Europaplatz 5<br/>
            64293 Darmstadt<br/>
            E-Mail datenschutz@cosynus.de<br/>
            www.cosynus.de</p>
        <p>Als Datenschutzbeauftragter wurde DataCo GmbH, Herr Robert Mäckle, Siegfriedstraße 8, 80803 München benannt.
            Sie erreichen unseren Datenschutz-beauftragten am besten per E-Mail an datenschutz@cosynus.de oder
            schriftlich an der oben angegebenen Adresse.</p>
        <p>Dienstanbieter gem. § 13 Telemediengesetz (TMG) ist die COSYNUS GmbH, Europaplatz 5, 64293 Darmstadt,
            www.cosynus.de</p>

        <h5>2. Erhebung und Speicherung personenbezogener Daten sowie Art, Zweck und Verwendung</h5>
        <p>Im Folgenden möchten wir Sie darüber informieren, welche personenbezogenen Daten wir verarbeiten und zu
            welchen Zwecken wir dies tun.
        </p>
        <p>Gemäß der „Verordnung zur Beschränkung von sozialen Kontakten und des Betriebes von Einrichtungen und von
            Angeboten aufgrund der Corona-Pandemie (Corona-Kontakt- und Betriebsbeschränkungsverordnung) vom 7. Mai
            2020“ der hessischen Landesregierung sind die Kontaktdaten (Name, Anschrift und E-Mail-Adresse und
            Telefonnummer) zur Ermöglichung der Nachverfolgung von Infektionen mit neuen Coronavirus (SARS-CoV-2) von
            der Betriebsinhaberin oder dem Betriebsinhaber zu erfassen. Diese Daten sind für die Dauer eines Monats ab
            Beginn des Besuchs geschützt vor Einsichtnahme durch Dritte für die zuständigen Behörden vorzuhalten und auf
            Anforderung an diese zu übermitteln sowie unverzüglich nach Ablauf der Frist zu löschen oder zu vernichten.
            Ihr Geschäftspartner hat uns im Rahmen eines Auftragsverarbeitungsvertrag (AVV) dafür beauftragt. </p>
        <p>Alle nachfolgend beschriebenen Datenkategorien werden entweder von Ihnen zum Zeitpunkt des CheckIns
            eingetragen und an uns übertragen oder wir bekommen sie direkt von Ihrem mobilen Gerät übertragen: </p>
        <p>
            <ul>
                <li>Im Rahmen des SmartMeeting Corona-Tracing werden Ihre Kontaktdaten übertragen. Diese bestehen aus
                    Vor- und Nachnamen, Anschrift mit Straße PLZ und Ort, E-Mail-Adresse und Mobilfunknummer bzw.
                    Telefonnummer. Sofern Sie in Begleitung von Personen aus Ihrem Hausstand sind, werden auch deren
                    Vor- und Nachnamen übertragen. Um ein schnelles Corona-Tracing zu ermöglichen und die Datenqualität
                    zu verbessern, verifizieren wir Ihre E-Mail-Adresse oder Mobilfunknummer mit einem dem Double-Opt-In
                    vergleichbaren Verfahren. Dies bedeutet, dass wir Ihnen eine Nachricht zur Bestätigung an die von
                    Ihnen angegebene E-Mail-Adresse oder Mobilfunknummer senden.
                </li>
                <li>Neben den Kontaktdaten werden auch Ihre Besuchsdaten übertragen. Diese bestehen aus der Uhrzeit
                    Ihres Besuchs mit Beginn und Ende.
                </li>
            </ul>
        </p>

        <p>Die von uns bereitgestellte SmartMeeting App erlaubt es Ihnen digitale COVID-Zertifikate zu speichern und in
            elektronischer Form bei sich zu führen. Ein digitales COVID-Zertifikat ist ein Nachweis dafür, dass eine
            Person eine Schutzimpfung gegen COVID-19 erhalten hat (COVID-Impfzertifikat), negativ auf COVID-19 getestet
            wurde (COVID-Testzertifikat) oder von einer COVID-19-Erkrankung genesen ist (COVID-Genesenenzertifikat). Die
            digitalen COVID-Zertifikate (offizielle Bezeichnung lautet „Digitales COVID-Zertifikat der EU“) gelten ab
            dem 1. Juli 2021 innerhalb der Europäischen Union (EU) als Bescheinigung von COVID-19-Impfungen und -Tests
            sowie der Genesung von einer COVID-19-Infektion. Ein COVID-Zertifikat erhalten Sie auf Wunsch nach einer
            Impfung, einem Test oder nach einer durchgemachten COVID-19-Erkrankung von einer zuständigen Einrichtung
            (Impfzentren, Teststellen, Ärzte oder Apotheken). Wenn Sie ein COVID-Zertifikat auf Ihrem Smartphone
            speichern möchten, können Sie den QR-Code einfach mit der SmartMeeting App über die Kamera ihres Geräts einscannen. Die SmartMeeting App
            speichert dann eine elektronische Version des COVID-Zertifikats sicher auf Ihrem Smartphone. Die Speicherung
            findet dabei ausschließlich offline in der SmartMeeting App statt und es werden dabei keine Daten an uns,
            das RKI oder sonstige Empfänger (andere Gesundheitsbehörden in Deutschland oder anderen Ländern, Apple,
            Google und sonstige Dritte) weitergegeben. Bitte beachten Sie, dass die QR-Codes der COVID-Zertifikate
            Gesundheitsdaten (Daten über Corona-Impfungen, Corona-Testergebnisse oder durchgemachte Corona-Infektionen)
            enthalten. Zeigen Sie die Zertifikate und QR-Codes nur vor, wenn Sie einen entsprechenden Nachweis erbringen
            möchten. Stellen Sie die QR-Codes niemandem zur Verfügung, wenn Sie nicht wollen, dass die Daten ausgelesen
            werden. Mit der SmartMeeting App können Sie eigene COVID-Zertifikate und COVID-Zertifikate von
            Familienmitgliedern (Familienzertifikate) scannen und verschlüsselt auf Ihrem Smartphone speichern. Zeigen
            Sie Familienzertifikate nur vor, wenn dies für die Wahrnehmung der Rechte Ihrer Familienmitglieder
            erforderlich ist. Um in den gesetzlich vorgesehenen Fällen gegenüber Dritten eine Impfung, ein negatives
            Testergebnis oder eine durchgemachte COVID-19-Erkrankung nachzuweisen, können Sie das entsprechende
            COVID-Zertifikat der prüfenden Person vorzeigen. Wenn die prüfende Person eine spezielle Prüf-App bzw.
            -funktion nutzt, genügt es den QR-Code des Zertifikats vorzuzeigen und scannen zu lassen.</p>

        <p><b>Im Zusammenhang mit dem digitalen COVID-Zertifikat werden die folgenden Daten und die für die jeweilige
            Art des Zertifikats notwendigen Informationen ausschließlich auf Ihrem mobilen Gerät gespeichert:</b></p>

        <ul>
            <li>Daten zu Ihrer Person (Name, Vorname, Geburtsdatum)</li>

            <li>Elektronische Signatur des RKI</li>

            <li>Eindeutige Kennung des Impf-, Genesenen- oder Testzertifikats</li>

            <li>Zusätzlich bei Impfzertifikaten: Informationen zum Impfstoff (Krankheit, Impfstoff, Produkt,
                Hersteller), Informationen zur Impfung (Dosennummer, Gesamtdosen, Impfdatum, Land, Aussteller)
            </li>

            <li>Zusätzlich bei Genesenenzertifikaten: das Datum der Testung, Angaben zur Testung, einschließlich der
                Art der Testung, und zum Aussteller
            </li>

            <li>Zusätzlich bei Testzertifikaten: Informationen zum Test (Krankheit, Art des Tests, Produkt,
                Hersteller), Informationen zur Testdurchführung (Datum und Uhrzeit des Tests sowie Ort des
                Testzentrums), Testergebnis
            </li>
        </ul>

        <p>Die Daten werden in der App gespeichert, sobald Sie den QR-Code mit der Kamera Ihres Geräts scannen. Diese Daten wurden zuvor bei Ihrer
            Impfung, bei der Anforderung des Genesenenzertifikats bzw. im Rahmen der Durchführung des Tests erhoben.</p>

        <p>Mit einer Prüf-App bzw. -funktion wird der QR-Code über die Kamera des Geräts gescannt, um die Gültigkeit eines vorgezeigten
            COVID-Zertifikats zu überprüfen. Bei der Prüfung werden die im Zertifikat enthaltenen Daten ausgelesen. Es
            wird nur angezeigt, ob das vorgelegte Zertifikat gültig ist. Im Falle eines gültigen Zertifikats wird
            zusätzlich der Name und das Geburtsdatum des Zertifikatsinhabers mitgeteilt und ob es sich um ein
            Testzertifikat handelt oder nicht. Bei Testzertifikaten wird zudem der Zeitpunkt der Probenahme angezeigt.
            Der Name und das Geburtsdatum des Zertifikatsinhabers werden angezeigt, damit die prüfende Person diese
            Angaben mit einem Identitätsnachweis (z. B. Reisepass oder Personalausweis) abgleichen kann. Die Mitteilung,
            ob es sich um ein Testzertifikat handelt und der Zeitpunkt der Probenahme sind erforderlich, damit die
            prüfende Person beurteilen kann, ob das dem Zertifikat zugrunde liegende Testergebnis noch gültig ist. Zum
            Schutz vor Fälschungen muss die Echtheit der gespeicherten COVID-Zertifikate überprüft werden. Hierzu wird
            die im QR-Code eines Zertifikats enthaltene elektronische Signatur verwendet. Diese wird bei der Erstellung
            des COVID-Zertifikats vom RKI auf Basis der im Zertifikat enthaltenen Daten erzeugt. Bei der Signatur
            handelt es sich um eine besondere Form der Verschlüsselung, die wie eine elektronische Unterschrift des RKI
            funktioniert und sicherstellt, dass es sich um ein offiziell vom RKI erstelltes digitales Dokument handelt.
            Das RKI stellt zudem die zugehörigen öffentlichen Schlüssel (sogenannte Public Keys) der
            Zertifikats-ausstellenden Behörden (in Deutschland das RKI) bereit. Mit diesen kann geprüft werden, ob die
            elektronische Signatur des Zertifikats tatsächlich von der ausstellenden Behörde stammt und ob das
            Zertifikat nach der elektronischen Signierung manipuliert worden ist. Die App lädt regelmäßig im Hintergrund
            die öffentlichen Schlüssel herunter und speichert diese lokal auf Ihrem Smartphone. So kann die App die
            Gültigkeit der elektronischen Signatur und damit die Echtheit der gespeicherten Zertifikate überprüfen. Die
            öffentlichen Schlüssel enthalten keine personen-bezogenen Daten.</p>

        <h5>3. Weitergabe von Daten an Dritte</h5>
        <p>Eine Übermittlung Ihrer personenbezogenen Daten an Dritte findet grundsätzlich nicht statt. Ausnahmen hiervon
            gelten nur, soweit dies für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist. Hierzu
            zählt insbesondere die Weitergabe an von uns beauftragte Dienstleister (sogenannte Auftragsverarbeiter) oder
            sonstige Dritte, deren Tätigkeit für die Vertragsdurchführung erforderlich ist. Die weitergegebenen Daten
            dürfen von den Dritten ausschließlich zu den genannten Zwecken verwendet werden. </p>
        <p>Die Telefonnummer des Mobilfunkgeräts, mit dem auf dem SmartMeeting Dienst zugegriffen wird, prüfen wir
            automatisch bei der Registrierung und in regel-mäßigen Abständen. Hierfür nutzen wir die Funktion Mobile
            Connect Verified MSISDN zur Überprüfung bzw. Abfrage der Telefonnummer des Mobilfunkgeräts (MSISDN) bei der
            Deutschen Telekom. Mehr Informationen erhalten Sie unter <a
                href="https://www.telekom.de/datenschutzhinweise/download/999.pdf">www.telekom.de/datenschutzhinweise/download/999.pdf</a>.
            Hierzu haben wir einen Auftragsverarbeitungsvertrag
            gemäß Artikel 28 Absatz 3 DS-GVO geschlossen. Hierbei handelt es sich um eine von der Deutschen Telekom in
            Zusammenarbeit mit anderen Netzbetreibern entwickelte Methode zur sicheren Überprüfung der Telefonnummer des
            Mobilfunkgeräts. Die Maßnahme dient dazu, die Richtigkeit und Gültigkeit der Telefonnummer sicherzustellen,
            damit in einem Infektionsfall eine Erreichbarkeit unter dieser Telefonnummer gewährleistet werden kann.
            Sofern die automatische Überprüfung nicht möglich ist, senden wir eine SMS zur Prüfung an die angegebene
            Telefonnummer zur manuellen Bestätigung. </p>
        <p>Die Daten des SmartMeeting Dienstes speichern wir in der Open Telekom Cloud (OTC) der Deutschen Telekom.
            Hierzu haben wir einen Auftragsverarbeitungs-vertrag gemäß Artikel 28 Absatz 3 DS-GVO geschlossen. Der
            Betrieb der OTC und die Sicherung der Daten erfolgt in hochsicheren Twin-Core-Rechenzentren der Telekom in
            Deutschland in Biere und Magdeburg nach deutschen Daten-schutzstandards. Um die aktuellsten Sicherheits- und
            Datenschutzanforderungen zu erfüllen, unterliegen alle Services Telekom-eigenen, strengen Vorgaben und
            werden regelmäßig von unabhängigen Institutionen geprüft und zertifiziert. Die DEKRA hat die Open Telekom
            Cloud für Datenschutz und Datensicherheit zertifiziert. Nach umfangreicher Prüfung bescheinigte die DEKRA,
            dass die Open Telekom Cloud den Ansprüchen der global anerkannten Normen ISO 27017 für Datensicherheit und
            ISO 27018 für Datenschutz genügt. Ein externer Audit des TÜV erwies, dass die Open Telekom Cloud auch die
            Ansprüche der Cloud Security Alliance an Datensicherheit erfüllt. Der CSA Star ist eines der wichtigsten
            international anerkannten Zertifikate für Datensicherheit im Cloud Computing. Die Open Telekom Cloud setzt
            zudem auf die Strategie Zero Outage. Hierbei handelt es sich um eine von der T-Systems entwickelte,
            TÜV-zertifizierte Methode für Qualitätssicherung und präventives Risiko-Management. Weiterhin hat die Lösung
            das interne Enterprise-Security-Architektur-Siegel (Esaris) zur Erfüllung sicherheitsrelevanter
            Anforderungen für ICT-Services der Deutschen Telekom. </p>
        <p>Wir behalten uns allerdings vor, Informationen über Sie offenzulegen, wenn wir gesetzlich hierzu verpflichtet
            sind oder die Herausgabe von Behörden oder Straf-verfolgungsorganen (z.B. Polizei oder Staatsanwaltschaft)
            von uns verlangt wird. </p>

        <h5>4. Ihre Rechte als betroffene Person</h5>
        <p>Ihnen als von der Datenverarbeitung betroffenen Person stehen verschiedene Rechte zu:</p>
        <p>
            <ul>
                <li>Widerrufsrecht: Von Ihnen erteilte Einwilligungen können Sie jederzeit uns gegenüber widerrufen. Die
                    Datenverarbeitung, die auf der widerrufenen Einwilligung beruht, darf dann für die Zukunft nicht
                    mehr fortgeführt werden.
                </li>
                <li>Auskunftsrecht: Gemäß der „Verordnung zur Beschränkung von sozialen Kontakten und des Betriebes von
                    Einrichtungen und von Angeboten aufgrund der Corona-Pandemie (Corona-Kontakt- und
                    Betriebs-beschränkungsverordnung) vom 7. Mai 2020“ der hessischen Landesregierung finden die Art. 13
                    (Informationspflicht) und Art. 15 (Auskunftsrecht der betroffenen Person) keine Anwendung.
                </li>
                <li>Berichtigungsrecht: Sie können die Berichtigung unrichtiger oder die Vervollständigung Ihrer bei uns
                    gespeicherten personenbezogenen Daten verlangen.
                </li>
                <li>Löschungsrecht: Sie können die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten
                    verlangen, soweit deren Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung und
                    Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses
                    oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist.
                </li>
                <li>Recht auf Einschränkung der Verarbeitung und Recht auf Datenübertragbarkeit: Gemäß der „Verordnung
                    zur Beschränkung von sozialen Kontakten und des Betriebes von Einrichtungen und von Angeboten
                    aufgrund der Corona-Pandemie (Corona-Kontakt- und Betriebs-beschränkungs-verordnung) vom 7. Mai
                    2020“ der hessischen Landesregierung finden die Art. 18 (Recht auf Einschränkung der Verarbeitung)
                    und Art. 20 (Recht auf Datenübertragbarkeit) der Datenschutz-Grundverordnung keine Anwendung.
                </li>
                <li>Beschwerderecht: Sie können sich bei der für uns zuständigen Aufsichtsbehörde beschweren, zum
                    Beispiel wenn Sie der Ansicht sind, dass wir Ihre personenbezogenen Daten in unrechtmäßiger Weise
                    verarbeiten. Die für uns zuständige Behörde ist:<br/><br/>
                    Der Hessische Datenschutzbeauftragte<br/>
                    Gustav-Stresemann-Ring 1<br/>
                    65189 Wiesbaden<br/>
                    Telefon +49 611 14080<br/>
                    Telefax +49 611 1408900<br/>
                    E-Mail poststelle@datenschutz.hessen.de<br/>
                    www.datenschutz.hessen.de<br/>
                </li>
            </ul>
        </p>
        <p>Alle Informationswünsche, Auskunftsanfragen, Widerrufe von Einwilligungen, Widersprüche und sonstige Anliegen
            zur Datenverarbeitung können Sie per
            E-Mail an datenschutz@cosynus.de richten.</p>

        <h5>5. Ihr Recht auf Widerspruch</h5>
        <p>SSofern wir Ihre personenbezogenen Daten auf Basis eines berechtigten Interesses verarbeiten, haben Sie das
            Recht, Widerspruch gegen diese Verarbeitung einzulegen. Möchten Sie von Ihrem Widerspruchsrecht Gebrauch
            machen, genügt es eine E-Mail an datenschutz@cosynus.de zu senden. </p>
        <h5>6. Datensicherheit</h5>
        <p>Wir setzen angemessene technische und organisatorische Maßnahmen zur Gewährleistung der Datensicherheit ein,
            insbesondere zum Schutz Ihrer personenbezogenen Daten gegen Kenntniserlangung durch Dritte, zufällige oder
            vorsätzliche Veränderung, Verlust oder Zerstörung. Diese werden jeweils regelmäßig überprüft und
            entsprechend dem aktuellen Stand der Technik angepasst. Die Übertragung Ihrer personenbezogenen Daten an uns
            erfolgt grundsätzlich verschlüsselt.

        </p>
        <h5>7. Speicherdauer</h5>
        <p>Für das Corona-Tracing werden die Kontakt- und Besuchsdaten einem Monat nach dem jeweiligen Besuchstermin
            automatisch gelöscht. Sofern Sie sich bei SmartMeeting als Benutzer registrieren, verarbeiten und speichern
            wir Ihre SmartMeeting-Benutzerdaten im Grundsatz für die Dauer unserer Vertrags-beziehung. Darüber hinaus
            unterliegen wir verschiedenen Aufbewahrungs- und Dokumentationspflichten. Die dort vorgegebenen Fristen,
            z.B. aus dem Steuerrecht, können bis zu 10 Jahren betragen. Des Weiteren können spezielle gesetzliche
            Vorschriften eine längere Aufbewahrungsfrist notwendig machen, zum Beispiel Beweise im Zusammenhang mit
            gesetzlichen Verjährungsfristen. Sind Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten
            nicht mehr erforderlich, werden diese regelmäßig gelöscht, es sei denn, deren begrenzte Weiterverarbeitung
            ist zur Erfüllung der oben aufgeführten Zwecke notwendig. </p>

        <p><br/>Gültig ab 15. September 2021 - bis dahin gelten die <Link to={"/privacy-august"}>aktuellen
            Datenschutzhinweise.</Link>
        </p>
    </Container>);
}

export default PrivacyScreen