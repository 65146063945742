import React, {useEffect, useState} from "react";
import axios from "../../axios/Instances/axios-instance";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import './ShowPartnerScreen.css';

const ShowPartnerScreen = () => {

    const [partner, setPartner] = useState(undefined);

    useEffect(() => {
        axios.get('/api/plugin/v1/list/gastro', {})
            .then(response => {
                setPartner(response.data);
            }).catch(error => {
        });
    }, []);

    if (!partner) {
        return (
            <Container></Container>
        );
    }

    //log.debug(PartnerScreen);
    return (

        <Container>
            {partner.map((partnerItem,i) => (
                <Row className="PartnerRow" key={i}>
                    <Col sm={2} className="text-center">
                        <img width="50%" alt={partnerItem.name} src={"https://api.smartmeeting.online/" + partnerItem.logo_url}/>
                    </Col>
                    <Col className="PartnerCol">
                        <p className="text-center">{partnerItem.name}</p>
                    </Col>
                </Row>))}
        </Container>)
}

export default ShowPartnerScreen;