import React from 'react';

import './TitleAndText.css';


const titleAndText = (props) => (
    <React.Fragment>
        {props.salutation != null &&
        <p className="">{props.salutation}</p>}
        {props.salutation == null &&
        <p className="">Schön, dass Sie unser Gast sind! Checken Sie sicher bei uns ein.</p>}
    </React.Fragment>
);

export default titleAndText;