import React from "react";
import FlexFill from "./FlexFill";
import BoxedLayout from "./BoxedLayout";
import BottomSpacing from "./BottomSpacing";

const ScreenNoAdArea = ({logoUrl, children, adType, success}) => {
    if (!success) success = false;
    return (
        <FlexFill className={'overflow-hidden'}>
            <FlexFill className={'overflow-auto'}>
                <BoxedLayout success={success} logoUrl={logoUrl}>
                    {children}
                </BoxedLayout>
            </FlexFill>
            <BottomSpacing/>
        </FlexFill>
    );
}

export default ScreenNoAdArea;

