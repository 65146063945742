import React from 'react'
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import classes from './Header.module.css'
import {ReactComponent as SmartMeetingLogo} from '../../../assets/images/sm-logo.svg'
import {ReactComponent as Claim} from '../../../assets/images/sm-claim.svg'
import {Link} from "react-router-dom";
import useGlobal from "../../../store/store";
import {useHistory} from "react-router";
import log from "../../../hoc/Logger";

const Header = (props) => {

    const [globalState, globalActions] = useGlobal();
    const history = useHistory();

    const showMenu = (props.showMenu !== false);

    log.debug(globalState.auth.loggedInUser);

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className={classes.AppHeader}>
                {showMenu && (<Navbar.Brand as={Link} to="/"><SmartMeetingLogo height="25px" width="25px"/><Claim
                    height="20px"/></Navbar.Brand>)}
                {!showMenu && (<Navbar.Brand><SmartMeetingLogo height="25px" width="25px"/><Claim
                    height="20px"/></Navbar.Brand>)}
                {showMenu && (<React.Fragment><Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            {globalState.tenant && globalState.auth.name && (
                                <Navbar.Text title={globalState.tenant.name} id="basic-nav-dropdown">
                                </Navbar.Text>)}
                        </Nav>


                        {!globalState.auth.loggedInUser &&
                        <Nav.Item>
                            <Nav.Link as={Link} to={'/checkin/current'} eventKey="currentCheckin">Aktueller
                                Check-In</Nav.Link>
                        </Nav.Item>
                        }

                        {globalState.auth.loggedInUser && (
                            <Nav className="justify-content-end">

                                <Nav.Item>
                                    <Nav.Link>{globalState.auth.loggedInUser.display_name}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="currentCheckin" as={Link} to={'/checkin/current'}>Aktueller
                                        Check-In</Nav.Link>
                                </Nav.Item>
                                {globalState.auth.loggedInUser !== null && globalState.auth.loggedInUser.managed_plugins !== null &&
                                globalState.auth.loggedInUser.managed_plugins.length > 0 && (
                                    <NavDropdown title="Verwalten" id="collasible-nav-dropdown">
                                        {globalState.auth.loggedInUser.managed_plugins && globalState.auth.loggedInUser.managed_plugins.length > 0 && globalState.auth.loggedInUser.managed_plugins.map((item) => {
                                            return (<NavDropdown.Item as={Link} to={"/admin/" + item.tenant_hash}
                                                                      key={item.tenant_hash}
                                                                      eventKey="item.tenant_hash">{item.tenant_name}</NavDropdown.Item>);
                                        })}

                                    </NavDropdown>)}
                                <Nav.Item>
                                    <Nav.Link eventKey="logOut" onClick={() => {
                                        globalActions.auth.logOut();
                                        history.push('/')
                                    }}>Logout</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link as={Link} to={'/impressum'} eventKey="impressum">Impressum</Nav.Link>
                                </Nav.Item>
                            </Nav>

                        )}


                        {!globalState.auth.loggedInUser && (
                            <>
                                <Nav.Item className="justify-content-end">
                                    <Nav.Link eventKey="login" as={Link} to="/login">Login</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link as={Link} to={'/impressum'} eventKey="impressum">Impressum</Nav.Link>
                                </Nav.Item>
                            </>
                        )}

                    </Navbar.Collapse></React.Fragment>)}


            </Navbar>
        </>
    )


}

export default Header;
