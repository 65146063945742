import React from "react";
import {Container} from "react-bootstrap";
import './TermsScreen.css'

const PrivacyScreenMay = () => {

    return (<Container>
        <h5>Nutzungsbedingungen für die SmartMeeting Kontakte App</h5>

        <p>Stand: 22. Mai 2020 </p>
        <p><i>Wichtig: Bitte lesen Sie die folgenden Nutzungsbedingungen sorgfältig durch, bevor Sie die Nutzung der
            SmartMeeting Kontakte App (nachfolgend „App“) fortsetzen. Indem Sie die Weppapp nutzen oder das Produkt aus
            dem Apple App Store bzw. aus dem Google Play Store herunterladen und diese Bedingungen akzeptieren kommt ein
            Lizenzvertrag über die Nutzung der App und des Dienstes zwischen Ihnen (nachfolgend „Nutzer“) und der
            COSYNUS GmbH, Europaplatz 5, 64293 Darmstadt (nachfolgend „COSYNUS“) zustande. Weder Apple Inc. oder ein
            verbundenes Unternehmen von Apple, noch Google Inc. bzw. ein verbundenes Unternehmen von Google werden
            Vertragspartner des Lizenzvertrags. Apple bzw. Google sind aber berechtigt, Ihre Pflichten aus diesem
            Lizenzvertrag gegen Sie durchzusetzen. Mit der Nutzung der App erklären Sie sich mit den nachfolgenden
            Lizenz- und Nutzungsbedingungen einverstanden und erkennen diese uneingeschränkt an. </i></p>


        <h5>§1 Nutzung und Geltungsbereich </h5>
        <ul className="ListNumbered">
            <li>[AGB] Die nachfolgenden Nutzungsbedingungen gelten in der jeweiligen Fassung ergänzend neben den
                Allgemeinen Geschäftsbedingungen der COSYNUS GmbH. Diese können in der jeweils aktuellen Fassung über
                den folgenden Link abgerufen werden: AGB der COSYNUS GmbH
            </li>
            <li>[Verbindungskosten/Telekommunikationsentgelte] Die Nutzung der App erfordert eine bestehende
                Datenverbindung des Smartphones bzw. Tablet-Computers bzw. wird diese durch die Nutzung dieser
                Funktionalitäten hergestellt. Die Nutzung der App ist daher ausschließlich bei Vorhandensein einer
                Daten-Flatrate oder in einem Wifi-Netzwerk empfohlen, um zusätzliche Verbindungskosten zu vermeiden.
                COSYNUS ist nur zur Bereithaltung der vom Nutzer angeforderten Inhalte verpflichtet. Für die Übertragung
                dieser Inhalte ist alleine der jeweilige Mobilfunkanbieter des Nutzers zuständig und verantwortlich. Die
                für die Übermittlung dieser angeforderten Inhalte anfallenden Telekommunikationsentgelte sind alleine
                vom Nutzer zu tragen.
            </li>
            <li>[Nutzungsrecht] Mit dem Download der App in der kostenfreien Grundversion gewährt COSYNUS dem Nutzer
                das unentgeltliche, nicht ausschließliche, nicht übertragbare Recht zur Nutzung der App auf einem in
                seinem Eigentum stehenden bzw. in seinem Besitz befindlichen Gerät, auf dem die App laut
                Systemanforderungen, die der Nutzer vor Abschluss dieser Bedingungen im Apple App Store bzw. im Google
                Play Store einsehen kann, lauffähig ist. Die Nutzung unterliegt den hier vorliegenden
                Nutzungsbedingungen und ferner den Nutzungsbedingungen und der Endbenutzervereinbarung vom Apple App
                Store bzw. Google Play Store. COSYNUS ist alleinige und ausschließliche Eigentümerin der Rechte an der
                App. Der Nutzer erhält außer den hier beschriebenen Nutzungsrechten keine weiteren Rechte.
            </li>
            <li>[Werbeeinblendungen] Bei einer unentgeltlich eingeräumten Lizenz behält sich COSYNUS das Recht vor,
                innerhalb der App Werbung einzublenden bzw. einblenden zu lassen, ohne dass der Nutzer die Möglichkeit
                hat, solche Werbung zu unterdrücken.
            </li>
            <li>[Weiterentwicklung] Die App wird ständig weiterentwickelt und es werden daher in regelmäßigen Abständen
                neue Funktionen und Dienstleistungen über die kostenfreie Grundversion hinaus angeboten. Zusätzliche
                Funktionen und Dienstleistungen innerhalb der App können kostenpflichtig sein oder werden. In diesem
                Fall wird COSYNUS dem Nutzer die Preise und Leistungsmerkmale vorab mitteilen.
            </li>
            <li>[Nutzungsbedingungen] Es ist dem Nutzer nicht gestattet, die App und die Inhalte zu kopieren, zu
                verteilen oder anderweitig Dritten zur Verfügung zu stellen (einschließlich der Vermietung, Verpachtung,
                Leihgabe oder Unterlizenzierung). Er ist nicht berechtigt, den Programmcode der App oder Teile hiervon
                zu verändern, rückwärts zu entwickeln (reverse engineering), zu dekompilieren, zu disassemblieren oder
                den Quellcode auf andere Weise festzustellen sowie abgeleitete Werke hiervon zu erstellen. Die
                Bestimmungen der §§ 69d, 69e UrhG bleiben hiervon jedoch unberührt. Bei Verstößen gegen die
                Lizenzbedingungen behält sich COSYNUS die Geltendmachung von Schadensersatz vor und kann das erteilte
                Nutzungsrecht widerrufen, ohne dass ein Anspruch auf Rückzahlung einer etwaig geleisteten Lizenzgebühr
                besteht.
            </li>
            <li>7. [Nutzungsbedingungen bei Aktualisierungen] Die Nutzungsbedingungen gelten auch für alle
                Updates/Upgrades und Programmergänzungen der App, die über den Apple App Store bzw. den Google Play
                Store von COSYNUS zum Download bereitgestellt werden, soweit diese nicht Gegenstand einer gesonderten
                Lizenzvereinbarung sind. In diesem Fall sind ausschließlich die Bestimmungen der für das jeweilige
                Update/Upgrade bzw. die Programmergänzung gültigen Lizenzbedingungen maßgeblich. COSYNUS behält sich
                alle Rechte an der App vor.
            </li>
            <li>8. [Nutzungsbedingungen für Inhalte und Dienst von Drittanbietern] Die Inhalte und die Verfügbarkeit der
                Dienste sind alleine von dem jeweiligen Dienstanbieter abhängig. Für die Nutzung der Dienste von Dritten
                gelten ergänzend die Nutzungsbedingungen der jeweiligen Dienstanbieter. Sollte ein Dienstanbieter
                technische und/oder inhaltliche Änderungen vornehmen oder kommt es zu Störungen und/oder Ausfällen bei
                der Nutzung dieser Dienste, so ist es möglich, dass dieser Dienst nicht mehr oder nur noch eingeschränkt
                genutzt werden kann. Ein Anspruch des Nutzers auf Wiederherstellung der Nutzungsmöglichkeit dieses
                Dienstes besteht nicht. Insofern wird keine Gewährleistung für eine dauerhafte Nutzungsmöglichkeit
                dieses Dienstes übernommen; der gänzliche und/oder teilweise Wegfall dieser Dienste stellt keinen Mangel
                der App dar.
            </li>
        </ul>

        <h5>§2 Technische Anforderungen </h5>
        <ul className="ListNumbered">
            <li>[Systemanforderungen] Für die Nutzung der App ist ein mobiles Endgerät erforderlich, welches die
                Systemanforderungen erfüllt. Diese und weitere technische Voraussetzungen zur Nutzung der App sind in
                der Beschreibung der Applikation im jeweiligen App Store aufgeführt, insbesondere unterstützte
                Betriebssysteme, Betriebssystemversionen und Endgeräte, wie Smartphones und Tablet-Computer. COSYNUS ist
                nicht für die Einhaltung der Systemanforderungen verantwortlich. Dies obliegt ausschließlich dem Nutzer
                der App.
            </li>
            <li>[Aktualisierungen] Der Nutzer ist damit einverstanden, dass Updates/Upgrades und Programmergänzungen,
                zum Beispiel zur Fehlerbeseitigung, auch automatisch eingespielt werden. Erfordern diese weitergehende
                Berechtigungen, so wird der Nutzer vor der Installation hierauf hingewiesen. Stimmt er diesen
                weitergehenden Berechtigungen nicht zu, erfolgt keine Aktualisierung und es kann sein, dass hierdurch
                der Betrieb der App beeinträchtigt werden kann oder nicht mehr möglich ist.
            </li>
            <li>[Nicht-Inanspruchnahme von Aktualisierungen] Soweit der Nutzer die seitens COSYNUS angebotenen und
                online zur Verfügung gestellten Softwareaktualisierungen nicht in Anspruch nimmt, kann er sich im Rahmen
                der Gewährleistung und Haftung nicht auf einen etwaigen Softwaremangel berufen, soweit dieser etwaige
                Mangel durch die online zur Verfügung gestellten Softwareaktualisierungen hätte beseitigt werden können.
            </li>

        </ul>

        <h5>§3 Mitwirkungspflichten</h5>
        <ul className="ListNumbered">
            <li>[Sorgfaltspflichten] Bei der Nutzung der App hat der Nutzer die für einen Gebrauch notwendigen
                Sorgfaltspflichten einzuhalten und die mit der Software generierten Ergebnisse vor deren Verwendung in
                angemessenem Umfang zu prüfen. Insbesondere ist er verpflichtet, regelmäßig eine Sicherung seiner Daten
                vorzunehmen, damit diese im Falle eines Verlusts wiederhergestellt werden können.
            </li>
            <li>[Nutzerprofil] Der Nutzer ist bei der Registrierung seines Nutzerprofils verpflichtet, ausschließlich
                wahre, vollständige und nicht irreführende Angaben zu machen sowie seinen Klarnamen und keine Pseudonyme
                oder Künstlernamen zu verwenden. Der Nutzer ist weiterhin verpflichtet, die Daten hinsichtlich aller von
                ihm genutzten Anwendungen während der gesamten Vertragslaufzeit wahr und vollständig zu halten. Sofern
                der Nutzer ein Profilbild im Nutzerprofil verwendet, darf er nur ein solches Foto einsetzen, auf dem der
                Nutzer klar und deutlich erkennbar ist. Der Nutzer stellt sicher, dass die öffentliche Wiedergabe des
                von ihm übermittelten Profilbilds nach geltendem Recht erlaubt ist. Die Nutzung von Fotos oder
                Abbildungen anderer oder nicht existierender Personen oder anderer Wesen oder von Logos als Profilbild
                ist untersagt. Der Nutzer sichert zu, dass er volljährig ist. Für den Fall, dass der Nutzer in Rahmen
                bestimmter Anwendungen für eine juristische Person tätig wird, sichert der Nutzer zu, bevollmächtigt zu
                sein, um im Namen der juristischen Person tätig zu werden. Der Nutzer wird COSYNUS auf Anfrage die gemäß
                dieser Ziffer zugesicherten Angaben nachweisen.
            </li>
            <li>[Zugangsdaten] Der Nutzer ist verpflichtet alle Zugangsdaten, wie zum Beispiel den Benutzernamen und das
                Passwort sicher zu verwahren und geheim zu halten. COSYNUS wird das Passwort nicht an Dritte
                weitergeben, nur für den Anmeldevorgang benutzen und den Nutzer – vom eigentlichen Anmeldevorgang in der
                App abgesehen – zu keinem Zeitpunkt nach dem Passwort fragen. Aus Sicherheitsgründen und um Missbrauch
                vorzubeugen wird dem Nutzer dringend empfohlen, sein Passwort in regelmäßigen Abständen zu ändern. Die
                Sicherung und Aufbewahrung der Zugangsdaten fällt in den ausschließlichen Verantwortungsbereich des
                Nutzers. Der Nutzer übernimmt die volle Verantwortung für sämtliche Handlungen, die unter Verwendung
                seiner Zugangsdaten getätigt werden.
            </li>
            <li> [Nutzeridentität] COSYNUS kann technisch nicht mit Sicherheit feststellen, ob ein auf den für die App
                registrierter Nutzer tatsächlich diejenige Person darstellt, die der Nutzer vorgibt zu sein. COSYNUS
                leistet daher keine Gewähr für die tatsächliche Identität eines Nutzers.
            </li>
            <li> [Erhebung technischer Informationen] Der Nutzer willigt ein, dass zur Aufrechterhaltung und
                Verbesserung der Produktfunktionalitäten sowie zu statistischen Zwecken nicht-personenbezogene
                technische Informationen erhoben und an COSYNUS übertragen werden, welche die Anwendungssoftware und das
                Betriebssystem identifizieren. Personenbezogene Daten des Nutzers werden nur insoweit verarbeitet, als
                dies zur Durchführung der angebotenen Dienste erforderlich ist. Die Verarbeitung von personenbezogenen
                Daten erfolgt gemäß den Datenschutzhinweisen von COSYNUS.
            </li>
            <li> [Schutzrechte] Der Nutzer erkennt die Rechte von COSYNUS an der App und dem Produkt (Patente,
                Urheberrechte, Warenzeichen, Geschäftsgeheimnisse) uneingeschränkt an. Das betrifft auch das Copyright
                an Dokumentationen, die schriftlich oder auf Computerspeichermedien vorliegen. Er verpflichtet sich,
                diese Rechte zu wahren und alle Schritte zu unternehmen, um Beeinträchtigungen oder Verletzungen dieser
                Rechte durch Dritte, soweit diese durch ihn oder über ihn in den Besitz des Produktes gelangt sind, zu
                unterbinden und zu verfolgen.
            </li>
        </ul>

        <h5>§4 Gewährleistung und Haftung</h5>
        <ul className="ListNumbered">

            <li>[Gewährleistung] Das Produkt ist getestet und weist die angegebenen Funktionen auf. COSYNUS übernimmt
                keine Gewähr für die Eignung des Produktes bezüglich der beabsichtigten Verwendung des Nutzers. COSYNUS
                übernimmt weiterhin keine Gewähr dafür, dass die App oder die hierüber vermittelten Inhalte mit der
                Hardware und Software des mobilen Endgerätes des Kunden vereinbar sind. COSYNUS übernimmt darüber hinaus
                keine Gewähr dafür, dass diese Inhalte jederzeit oder zu bestimmten Zeiten uneingeschränkt zur Verfügung
                stehen oder gewisse Leistungs- und Funktionsanforderungen erfüllen.
            </li>
            <li>[Test- und Pilot-Versionen] COSYNUS übernimmt bezüglich der Überlassung von Alpha-, Beta-, Test- und
                Pilot-Versionen oder anderen nicht finalisierten Produkten keinerlei Haftung und Gewährleistung. Bei dem
                Einsatz solcher noch nicht zum Vertrieb freigegebener Software können Fehlfunktionen und sogar
                Datenverluste auftreten. Dem Nutzer sind diese Risiken bekannt und er akzeptiert sie auch. Der Einsatz
                derartiger Programme erfolgt auf eigene Gefahr des Nutzers und unterliegt einem vollständigen
                Gewährleistungs- und Haftungsausschluss, was der Nutzer ausdrücklich zur Kenntnis genommen hat und auch
                akzeptiert. Diese Versionen sind nur zu Testwecken zu verwenden und dürfen nicht öffentlich vorgeführt
                werden.
            </li>
            <li> [Nachbesserungen und Ersatzlieferungen] Die Gewährleistung erfolgt durch Nachbesserung bzw.
                Ersatzlieferung. Der Nutzer hat das Recht zur Wandlung oder Minderung nur, wenn die Nachbesserung bzw.
                Ersatzlieferung fehlgeschlagen ist und COSYNUS gegenüber eine Nachfrist von mindestens 30 Tagen gesetzt
                wurde. Eine weitergehende Gewährleistung ist ausgeschlossen.
            </li>
            <li> [Einstellung der App] COSYNUS behält sich unter Einhaltung einer Bekanntgabefrist von 60 Tagen vor, die
                App oder die darüber vermittelten Inhalte ganz oder teilweise zeitweise oder dauerhaft einzustellen.
                Weiterhin behält sich COSYNUS ausdrücklich das Recht vor, die kostenfreie App oder die hierüber
                vermittelten kostenfreien Inhalte zukünftig nur noch zur entgeltlichen Nutzung anzubieten. Der Nutzer
                hat keinerlei Anspruch auf unbegrenzte Nutzung dieser der App und/oder Dienste.
            </li>
            <li> [Haftung] COSYNUS, dessen gesetzliche Vertreter sowie Erfüllungsgehilfen haften dem Nutzer nur nach
                Maßgabe dieses Vertrages. Weitergehende Schadensersatzansprüche des Nutzers sind ausgeschlossen soweit
                sie nicht auf Vorsatz oder grober Fahrlässigkeit beruhen sowie im Falle von schuldhaft verursachten
                Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit. Die Haftung für indirekte
                Schäden ist ausgeschlossen. Für Sach- und Rechtsmängel haftet COSYNUS, gleich aus welchem Rechtsgrund,
                nur soweit COSYNUS den jeweiligen Sach- oder Rechtsmangel arglistig verschwiegen hat. Ansprüche nach dem
                Produkthaftungsgesetz bleiben unberührt.
            </li>

        </ul>

        <h5>§5 Datenschutz</h5>
        <p>[Datenschutzerklärung] Es gelten die Datenschutzhinweise für die SmartMeeting Kontakte App, die unter dem
            folgenden Link abgerufen werden können: <a
                href={"https://checkin.smartmeeting.online/privacy"}>Datenschutzhinweise</a></p>


        <h5>§6 Verstoß gegen diese Nutzungsbedingungen</h5>
        <ul className="ListNumbered">

            <li>
                [Nutzung des Diensts] Der Nutzer verpflichtet sich gegenüber COSYNUS zur Nutzung des Diensts nur zu
                Zwecken,
                die nicht gegen diese Nutzungsbedingungen oder gegen geltendes deutsches Recht verstoßen, oder Rechte
                Dritter verletzen. Wenn er einer anderen Person erlaubt, die App auf seinem mobilen Endgerät zu nutzen,
                gehört es zur Sorgfaltspflicht des Nutzers, auf die hier vorliegenden Nutzungsbedingungen hinzuweisen
                und
                deren Einhaltung angemessen zu kontrollieren.
            </li>
            <li> [Sperrung des Zugangs] COSYNUS darf den Zugang zu dem Dienst sperren, wenn der Nutzer gegen seine
                Pflichten aus diesen Bedingungen verstößt. Nach Sperrung des Zugangs ist der Nutzer zur Nutzung der App
                und
                des Dienstes nicht mehr berechtigt.
            </li>
            <li>[Kündigung des Lizenzvertrages] Verstößt der Nutzer gegen eine oder mehrere Lizenzbestimmungen, so kann
                COSYNUS weiterhin den Lizenzvertrag fristlos kündigen. Nach Beendigung des Vertrages ist der Nutzer zur
                Nutzung der App des Dienstes nicht mehr berechtigt.
            </li>


        </ul>

        <h5>§7 Änderungen der Nutzungsbedingungen</h5>
        <p>COSYNUS behält sich das Recht vor, diese Nutzungsbedingungen jederzeit ohne Angabe von Gründen zu ändern.</p>
        <h5>§8 Geltendes Recht und Gerichtsstand</h5>
        <p>Es gilt das materielle Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Für Nutzer,
            die keine Verbraucher sind oder die keinen ordentlichen Gerichtsstand in Deutschland haben, ist der
            Gerichtsstand für alle Streitigkeiten, die durch oder in Verbindung mit dieser Nutzungsbedingung entstehen,
            Darmstadt (Deutschland).</p>

        <h5>§9 Sonstiges</h5>
        <p>Sollten einzelne Bestimmungen dieser Nutzungsbedingung unwirksam oder undurchführbar sein oder werden, bleibt
            die Wirksamkeit der anderen Bedingungen im Übrigen unberührt. Die nichtigen bzw. unwirksamen
            Lizenzbestimmungen werden in einem solchen Fall durch solche wirksamen Bestimmungen ersetzt, die dem
            wirtschaftlichen Zweck der unwirksamen bzw. nichtigen Lizenzbestimmungen am nächsten kommen.</p>

        <p>Anbieter der SmartMeeting Kontakte App bzw. SmartMeeting Kontakte Web-App ist die:</p>

        <p> COSYNUS GmbH<br/>
            Europaplatz 5<br/>
            64293 Darmstadt<br/>
            www.cosynus.de</p>

    </Container>);
}

export default PrivacyScreenMay