import React from "react";
import axios from "./Instances/axios-instance";
import useGlobal from "../store/store";

import createAuthRefreshInterceptor from 'axios-auth-refresh';
import log from "../hoc/Logger";

const useAxiosWithJWT = () => {

    const [auth, authActions] = useGlobal(
        state => state.auth,
        actions => actions.auth
    );

    const inst = axios;

    React.useEffect(() => {
        log.debug('useEffect: ' + auth.refreshToken)
        const refreshAuthLogic = failedRequest =>
            inst.post('/auth/v2/refresh', null, {withCredentials: true}).then(tokenRefreshResponse => {
                log.debug('refresh triggered', auth.refreshToken)
                log.debug('new jwt token: ', tokenRefreshResponse.data.token);
                authActions.setJwtToken(tokenRefreshResponse.data.token)
                failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
                return Promise.resolve();
            });

        const interceptor = createAuthRefreshInterceptor(inst, refreshAuthLogic);
        log.debug(interceptor);
        return () => {
            log.debug('ejected ', interceptor);
            inst.interceptors.request.eject(interceptor);
            log.debug(inst.interceptors);
        }
        //setInst()
    }, [auth.refreshToken, authActions, inst]);

    return inst;

}

export default useAxiosWithJWT;
