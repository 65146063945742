import React from "react";
import classes from "../UI/Ads/AdArea.module.css";

const BottomSpacing = () => {

    return (
        <div className={classes.AdArea}>
            <div className={classes.AdAreaSeparator}/>
        </div>
    );
}

export default BottomSpacing;