import axios from "../axios/Instances/axios-instance";
import areas from '../common/constants/promise-areas';
import {trackPromise} from 'react-promise-tracker';
import log from "../hoc/Logger";

export const setTenant = (store, tenant_hash, force = false) => {
    log.debug('setting tenant ', tenant_hash)
    log.debug(store.state.tenant)
    if (store.state.tenant.tenant_hash === tenant_hash && tenant_hash != null && !force) {
        log.debug('tenant did not change')
        return null;
    }

    let config = {};
    if (store.state.auth.jwtToken != null) {
        config = {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.jwtToken
            }
        };
    }

    trackPromise(
        axios.get('/api/plugin/v1/checkin/' + tenant_hash, config)
            .then(response => {
                log.debug('new tenant', response.data);
                store.setState({tenant: {...response.data, tenant_hash: tenant_hash}});
            })
            .catch(error => {
            }),
        areas.tenant);
}