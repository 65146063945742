import React from "react";
import * as actions from "../actions";
import store from 'store';
import globalHook from 'use-global-hook';
import log from "../hoc/Logger";
import cookie from 'react-cookies';
import axios from "../axios/Instances/axios-instance";

const initialState = {
    auth: {
        jwtToken: store.get('user_jwt_token'),
        refreshToken: store.get('user_refresh_token'),
        loggedInUser: null
    },
    tenant: {
        tenant_hash: null
    },
    user: {
        currentCheckIn: null
    }
};

log.debug("init global hook");

const initializer = store => {
        log.debug('[InitialConfigFetcher] initializing');
        const localStore = require('store');

        // if (localStore.get('user_jwt_token')) {
        //     log.debug('[InitialConfigFetcher] load JWT Token');
        //     store.actions.auth.setJwtToken(localStore.get('user_jwt_token'));
        // }
        let data = null;
        if (localStore.get('user_refresh_token')) data = {refresh_token: localStore.get('user_refresh_token')};
        axios.post('/auth/v2/refresh', data, {withCredentials: true}).then(tokenRefreshResponse => {
            localStore.remove('user_refresh_token');
            localStore.remove('user_jwt_token');
            log.debug('response token: ', tokenRefreshResponse.data.token);
            store.actions.auth.setJwtToken(tokenRefreshResponse.data.token)
        }).catch(error => {
                log.debug('refresh not possible');
            }
        );

        if (localStore.get('user_current_checkin')) {
            //alert('bylocalStore')
            log.debug('[InitialConfigFetcher] load current checkin');
            store.actions.user.setCurrentCheckIn(localStore.get('user_current_checkin'));
        }

        if (!localStore.get('user_current_checkin')) {
            log.debug('no store checkin found');
            const checkInFromCookie = cookie.load('current_checkin');
            if (checkInFromCookie) {
                // alert('by cookie')
                log.debug('[InitialConfigFetcher] load current checkin from Cookie');
                store.actions.user.setCurrentCheckIn(checkInFromCookie);
            }
        }
    }
;

const useGlobal = globalHook(React, initialState, actions, initializer);

export default useGlobal;
