import React from "react";
import StoreButton from "../../components/UI/Ads/StoreButton";

const AppDownload = () => {
    return <React.Fragment>
        <h5 style={{paddingLeft: 20, paddingRight: 20, marginBottom: 20}}>Laden Sie sich SmartMeeting jetzt auf Ihr
            Smartphone!</h5>

        <p>Führen Sie nahezu automatisch ein persönliches Kontaktbuch: Scannen Sie die SmartMeeting QR-Codes der
            Personen, mit denen Sie sich treffen und erfassen Sie andere Kontakte manuell.</p>

        <p>Im SmartMeeting Kontaktbuch können Sie anschließend schnell und chronologisch Ihre Kontakte in Restaurants,
            Vereinen und Unternehmen sowie alle persönlichen Treffen nachvollziehen.</p>

        <p style={{marginTop: 40}}>
            <div align={"center"}
                 style={{marginBottom: 15}}>< StoreButton store={"appstore"} height={50}></StoreButton></div>
            <div align={"center"}><StoreButton store={"playstore"} height={50}></StoreButton></div>
        </p>
    </React.Fragment>
}

export default AppDownload
;