import React, {useEffect, useRef, useState} from 'react';
import {ErrorMessage, Field, Formik} from 'formik';
import {Col, Container, Form} from 'react-bootstrap';
import TitleAndText from '../TitleAndText/TitleAndText';
import GuestFormArray from "./guestsFormArray";
import * as credentialFormBasics from './credentialBasics'
import {Link} from "react-router-dom";
import classes from './credentialForm.module.css';
import globalClasses from '../../App.module.css';
import useGlobal from "../../store/store";
import log from "../../hoc/Logger";
import * as Yup from "yup";
import getAdditionalFormElements, {
    prepareAdditionalFormData,
    updateInitialValuesAndValidation
} from "../../services/AdditionalFormService";
import DarkThemeButton from "../UI/Button/DarkThemeButton";
import Checkbox from "../UI/Input/checkbox";
import axios from '../../axios/Instances/axios-instance';
import {v4 as uuidv4} from "uuid";
import Spinner from "../UI/Spinner/Spinner";

var _ = require('lodash');


const CredentialForm = (props) => {

        const credentialFormInitialValues = credentialFormBasics.credentialFormInitialValues;

        const [credentialFormValidationSchema, setValidationSchema] = useState(credentialFormBasics.credentialFormValidationSchema);

        const [apiError, setApiError] = useState();

        const [checkMobileConnect, setCheckMobileConnect] = useState(false);

        const store = require('store');

        const form = useRef();

        var resolveMobileConnect;

        const mobileConnectCheck = (milliseconds) => {
            return new Promise(resolve => {
                resolveMobileConnect = resolve;
                setTimeout(resolve, milliseconds);
            })
        }

        const tenant = useGlobal(
            state => state.tenant,
            actions => actions.tenant
        )[0];

        const [additionalFormProcessed, setAdditionalFormProcessed] = useState(false);

        async function submitForm(values, props, headers, setSubmitting, setFieldError) {
            setSubmitting(true);
            let mobileConnectData = {};
            window.addEventListener("message", event => {
                console.log(event.data);
                if (event.data != null && !event.data.payload) {
                    mobileConnectData = JSON.parse(event.data);
                    console.log(mobileConnectData);
                    resolveMobileConnect();
                }
            });

            const res = await axios.get('https://scripting.smartmeeting.online/istmobile');
            console.log(res.data);
            if (res.data.isTMobile !== undefined && res.data.isTMobile) {
                setCheckMobileConnect(true);

                await mobileConnectCheck(5000);

                setCheckMobileConnect(false);
            }

            const attendeeList = values.attendees;
            const initiator = _.cloneDeep(values);
            _.unset(initiator, 'attendees');
            _.unset(initiator, 'terms');
            _.unset(initiator, 'privacy');

            setTimeout(() => {
                const checkInRequest = {
                    terms: 1,
                    privacy: 1,
                    resource: props.resource,
                    callback_url: process.env.REACT_APP_REDIRECT_URL,
                    initiator: initiator,
                    attendees: attendeeList
                }

                if (mobileConnectData.correlation_id !== undefined) {
                    checkInRequest['correlation_id'] = mobileConnectData.correlation_id
                    checkInRequest['code'] = mobileConnectData.code
                    if (process.env.REACT_APP_ENVIRONMENT === 'production')
                        checkInRequest['mc_environment'] = 'prod';
                }

                if (tenant.additional_form != null) {
                    checkInRequest[tenant.additional_form.identifier] = prepareAdditionalFormData(initiator, tenant);
                }

                axios.post('/api/plugin/v2/checkin', checkInRequest, {
                    headers: headers
                })
                    .then(response => {
                        log.debug('[credentialForm] props.success')
                        props.success(response.data)
                        setSubmitting(false);
                    })
                    .catch(error => {
                        if (error.response.status === 400 && error.response.data.form) {
                            //log.debug('error')
                            if (error.response.data.errors[0] === 'Not a valid mobile number.') {
                                setFieldError('mobile_phone_number', 'Keine gültige Mobilfunknummer');
                                setApiError('Es ist ein Fehler aufgetreten.');
                            }
                        } else {
                            setApiError('Es ist ein Fehler aufgetreten.');
                        }
                        setSubmitting(false);
                    });

            }, 1000);
        }

        useEffect(() => {


            let isPrefilled = false;

            const setFromStore = (fieldName) => {
                if (store.get('last_input.' + fieldName)) {
                    credentialFormInitialValues[fieldName] = store.get('last_input.' + fieldName);
                    isPrefilled = true;
                    log.debug('prefilled set');
                }
            }

            Object.entries(credentialFormInitialValues).map((fieldName, i) => {
                setFromStore(fieldName[0])
                return null;
            })
            log.debug('prefilled');
            if (isPrefilled) {
                // this.refs.formikForm.getFormikBag().validateForm();
                if (form.current != null)
                    form.current.validateForm();
            }

            if (tenant.additional_form && !additionalFormProcessed && form.current) {
                updateInitialValuesAndValidation(tenant.additional_form, form, credentialFormValidationSchema, setValidationSchema);
                setAdditionalFormProcessed(true);
            }

        }, [credentialFormInitialValues, store, tenant, additionalFormProcessed, credentialFormValidationSchema]);


        const headers = {
            'api-key': tenant.api_key
        }

        const handleFormChange = (values, dirty) => {
            if (dirty) {
                store.set('last_input.first_name', values.first_name)

                store.set('last_input.last_name', values.last_name)

                store.set('last_input.street', values.street)

                store.set('last_input.plz', values.plz)

                store.set('last_input.city', values.city)

                store.set('last_input.mobile_phone_number', values.mobile_phone_number)

                store.set('last_input.email_address', values.email_address)
            }
        };


        return (
            <Container fluid>
                <TitleAndText salutation={tenant.salutation}/>
                <Formik
                    innerRef={form}
                    initialValues={credentialFormInitialValues}
                    validationSchema={Yup.lazy(values => credentialFormValidationSchema)}
                    validateOnChange={true}
                    enableReinitialize
                    onSubmit={(values, {setSubmitting, setFieldError}) => {
                        //log.debug(values);
                        submitForm(values, props, headers, setSubmitting, setFieldError);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                          isInvalid,
                          dirty
                      }) => (
                        <Form onSubmit={handleSubmit}
                              onKeyUp={handleFormChange(values, dirty)}>
                            <Container className="text-center marginBottom">
                                <h6>{props.resourceName}</h6>
                            </Container>
                            <Form.Row>
                                <Form.Group as={Col} controlId="first_name">
                                    <Field type="text" as={Form.Control} name="first_name" placeholder="Vorname"
                                           isInvalid={touched.first_name && errors.first_name}
                                           autoComplete="given-name"/>
                                    <ErrorMessage as={Form.Control.Feedback} name="first_name" component="div"
                                                  className={classes.ErrorMessage}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="last_name">
                                    <Field type="text" as={Form.Control} name="last_name" placeholder="Nachname"
                                           isInvalid={touched.last_name && errors.last_name}
                                           autoComplete="family-name"/>
                                    <ErrorMessage as={Form.Control.Feedback} name="last_name" component="div"
                                                  className={classes.ErrorMessage}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="street">
                                    <Field type="text" as={Form.Control} name="street"
                                           placeholder="Straße Hausnummer"
                                           autoComplete="address-line1"
                                           isInvalid={touched.street && errors.street}/>
                                    <ErrorMessage as={Form.Control.Feedback} name="street" component="div"
                                                  className={classes.ErrorMessage}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="plz" xs={4}>
                                    <Field type="text" as={Form.Control} name="plz" placeholder="PLZ"
                                           isInvalid={touched.plz && errors.plz}
                                           autoComplete="postal-code"/>
                                    <ErrorMessage as={Form.Control.Feedback} name="plz" component="div"
                                                  className={classes.ErrorMessage}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="city">
                                    <Field type="text" as={Form.Control} name="city" placeholder="Ort"
                                           isInvalid={touched.city && errors.city}/>
                                    <ErrorMessage as={Form.Control.Feedback} name="city" component="div"
                                                  className={classes.ErrorMessage}/>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <ErrorMessage as={Form.Control.Feedback} name="email_address" component="div"
                                              className={classes.ErrorMessage}/>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="mobile_phone_number">
                                    <Field type="tel" as={Form.Control} name="mobile_phone_number"
                                           placeholder="Handynummer"
                                           isInvalid={touched.mobile_phone_number && errors.mobile_phone_number}/>
                                    <ErrorMessage as={Form.Control.Feedback} name="mobile_phone_number"
                                                  component="div"
                                                  className={classes.ErrorMessage}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="email_address">
                                    <Field type="text" as={Form.Control} name="email_address"
                                           placeholder="E-Mail Adresse (optional)"
                                           isInvalid={touched.email_address && errors.email_address}/>
                                </Form.Group>

                            </Form.Row>
                            {!tenant.additional_form && <GuestFormArray values={values}/>}
                            {tenant.additional_form && form.current && getAdditionalFormElements(credentialFormValidationSchema, tenant.additional_form, form,
                                setAdditionalFormProcessed, setValidationSchema)}
                            <Form.Row>
                                <Form.Group as={Col} controlId="privacy">
                                    <Checkbox name="privacy">Ich stimme den <Link
                                        className={globalClasses.ImportantLinkDark}
                                        to="/privacy">Datenschutzbedingungen</Link> zu</Checkbox>
                                </Form.Group>
                            </Form.Row>
                            {apiError &&
                            <Form.Row className="text-center">
                                <p className="text-center apiError">{apiError}</p>
                            </Form.Row>
                            }
                            <ul className={classes.ErrorList}>
                                {Object.values(errors).map((msg, idx) => {
                                    if (typeof msg === 'string')
                                        return (
                                            <li className={classes.ErrorMessage} key={msg + idx}>
                                                {msg}
                                            </li>
                                        );
                                    return null;
                                })}
                            </ul>
                            {!isSubmitting && <Form.Row>
                                <Form.Group as={Col} className="text-center">
                                    <DarkThemeButton type="submit" variant="secondary"
                                        //
                                    >Jetzt einchecken</DarkThemeButton>
                                </Form.Group>
                            </Form.Row>}
                            {isSubmitting && <div style={{marginBottom:'10px'}}><><p>Ihre Handynummer wird geprüft. Das kann einen Moment dauern.</p><Spinner /></></div>}
                        </Form>
                    )}
                </Formik>

                <Link to={{
                    pathname: '/login',
                    state: {
                        returnTo: '/checkin/tenant/' + tenant.tenant_hash
                    }
                }}>
                    <DarkThemeButton variant="secondary">Mit bestehendem Account einloggen</DarkThemeButton></Link>

                {checkMobileConnect && <iframe title={'Mobile Connect'} id={'mc_result'} hidden
                                                        src={'https://'+process.env.REACT_APP_MOBILECONNECT_URL+'/authorize?response_type=code&' +
                                                        'client_id='+process.env.REACT_APP_MOBILECONNECT_CLIENT_ID+'&' +
                                                        'scope=openid mc_vm_match_hash&' +
                                                        'version=mc_v2.1&' +
                                                        'redirect_uri=https://mobileconnect.smartmeeting.online&' +
                                                        'nonce=' + uuidv4() + '&' +
                                                        'acr_values=2&' +
                                                        'state=' + uuidv4() + '&' +
                                                        'client_name=Cosynus' +
                                                        '&correlation_id=' + uuidv4()}>iFrame</iframe>}
            </Container>
        )
    }
;

export default CredentialForm;