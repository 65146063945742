import React, {useEffect, useState} from "react";
import queryString from "query-string";
import axios from "../../axios/Instances/axios-instance";
import ResetPasswordForm from "../../components/Forms/resetPasswordForm";
import log from "../../hoc/Logger";
import {Link} from "react-router-dom";

const ResetPassword = (props) => {

    const [result, setResult] = useState('');

    const confirmationToken = (props.location.search) ? queryString.parse(props.location.search).ct : null;

    const setSuccess = () => {
        setResult('SET_SUCCESS');
    }

    useEffect(() => {

        axios.get("api/account/password/reset/request/" + confirmationToken).then((response) => {
                if (response.status === 200)
                    setResult('SUCCESS');
            }
        ).catch((error) => {
            log.debug(error);
            setResult(error.response.data.message);
        });
    }, [confirmationToken])

    if (result === '') return <div></div>;
    if (result === 'SUCCESS')
        return <ResetPasswordForm confirmationToken={confirmationToken} success={setSuccess}/>;
    if (result === 'SET_SUCCESS') {
        return <React.Fragment>
            <h5>Passwort geändert</h5>
            <p>Ihr Passwort wurde geändert und ist jetzt aktiv.</p>
            <p><Link to={"/login"}>Zum Web-Login</Link></p>

        </React.Fragment>
    }
    return <div>{result}</div>

}

export default ResetPassword;