import React, {useState} from 'react';
import {ErrorMessage, Field, Formik} from 'formik';
import axios from "../../../axios/Instances/axios-instance";
import * as Yup from 'yup';
import {Col, Container, Form} from 'react-bootstrap';
import {Link} from "react-router-dom";
import areas from '../../../common/constants/promise-areas';
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import Spinner from '../../UI/Spinner/Spinner';
import log from "../../../hoc/Logger";
import globalClasses from "../../../App.module.css";
import DarkThemeButton from "../../UI/Button/DarkThemeButton";

const initialValues = {
    username: '',
    password: '',
};


const validationSchema = Yup.object().shape({
    username: Yup.string().matches(/^[0-9_.a-zA-Z@+]*$/, 'Nur Buchstaben, Zahlen, Punkte und Unterstriche').required('Benutzername, Passwort oder E-Mail eingeben'),
    password: Yup.string().required('Passwort eingeben')
});

const LoginForm = (props) => {

    const {promiseInProgress} = usePromiseTracker({area: areas.auth, delay: 500});

    const [apiError, setApiError] = useState('');

    if (props.identifier) initialValues.username = props.identifier;

    return (
        <Container fluid>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, {setSubmitting, setFieldError}) => {
                    trackPromise(
                        axios.post('/auth/v2/login', {}, {
                            auth: {
                                username: values.username,
                                password: values.password
                            },
                            withCredentials: true
                        })
                            .then(response => {
                                log.debug(response.data)
                                props.success(response.data);
                            })
                            .catch(error => {
                                log.debug(error.response.data)
                                setApiError('Ihre Zugangsdaten sind ungültig.');
                                setSubmitting(false);
                            })


                        , areas.auth);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                      isInvalid,
                      dirty
                  }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="username">
                                <Field type="text" as={Form.Control} name="username" placeholder="Username"
                                       autoComplete="username"
                                       isInvalid={touched.username && errors.username}/>
                                <ErrorMessage as={Form.Control.Feedback} name="username" component="div"
                                              className={globalClasses.ErrorMessage}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="password">
                                <Field type="password" as={Form.Control} name="password" autoComplete="current-password"
                                       placeholder="Passwort"
                                       isInvalid={touched.password && errors.password}/>
                                <ErrorMessage as={Form.Control.Feedback} name="password" component="div"
                                              className={globalClasses.ErrorMessage}/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row className="text-center">
                            <Container><Link className="ImportantLink text-center" to={{
                                pathname: '/resetpwd',
                                state: {
                                    identifier: values.username
                                }
                            }}>Passwort vergessen?</Link></Container>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <p className="text-center apiError">{apiError}</p>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="text-center">
                                {promiseInProgress ? <Spinner/> :
                                    <DarkThemeButton type="submit" variant="secondary"
                                                     disabled={(!isValid || !dirty) || isSubmitting}>Login</DarkThemeButton>}
                            </Form.Group>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        </Container>)
};

export default LoginForm;