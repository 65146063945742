import React from "react";
import {Container} from "react-bootstrap";
import './PrivacyScreen.css'

const ImpressumScreen = () => {

    return (<Container>
        <h5>Impressum</h5>
        <p>COSYNUS GmbH<br/>
            Europaplatz 5<br/>
            DE-64293 Darmstadt<br/>
            Telefon +49 6151 9448-0<br/>
            Telefax +49 6151 9448-500<br/>
            info@cosynus.de</p>

        <p>Geschäftsführer:<br/>
            Michael Reibold</p>

        <p>Steuernummern:<br/>
            USt-ID DE158118724</p>

        <p>Gläubiger-Identifikationsnummer:<br/>
            DE76ZZZ00000663633</p>

        <p>Handelsregister:<br/>
            Amtsgericht Darmstadt, HRB 5559</p>

        <p>Bankdaten:<br/>
            Commerzbank Darmstadt, IBAN DE77 5088 0050 0179 4115 00, BIC DRESDEFF508<br/>
            Sparkasse Darmstadt, IBAN DE53 5085 0150 0002 0111 66, BIC: HELADEF1DAS</p>
    </Container>);
}

export default ImpressumScreen