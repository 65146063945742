import React, {useEffect} from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import classes from './Admin.module.css'
import {Link} from "react-router-dom";
import useGlobal from "../../store/store";
import {usePromiseTracker} from "react-promise-tracker";
import promiseAreas from "../../common/constants/promise-areas";
import Spinner from "../../components/UI/Spinner/Spinner";
import log from "../../hoc/Logger";

const AdminHomepageScreen = (props) => {

    const tenant_hash = props.match.params.tenant_hash;
    const loading = usePromiseTracker({area: promiseAreas.tenant, delay: 500})
    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    )

    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];

    useEffect(() => {
        const force = auth.jwtToken != null;
        if (force) log.debug('Forcing tenant reload with user-auth');
        tenantActions.setTenant(tenant_hash, force);
    }, [tenant_hash, tenantActions, auth.jwtToken])

    if (loading.promiseInProgress) return <Spinner/>
    log.debug(tenant);
    if (auth.jwtToken == null) return <div>Nicht angemeldet.</div>

    return (
        <div>
            <Card className={classes.ListCard}>
                <Card.Body className={classes.ListCardBody}>
                    <Row>
                        <Col>
                            <Link to={"/admin/" + tenant_hash + "/checkins"} className="ImportantLink">Aktuelle
                                Check-Ins</Link>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {tenant.menu_path && (
                <Card className={classes.ListCard}>
                    <Card.Body className={classes.ListCardBody}>
                        <Row>
                            <Col>
                                <Link to={"/admin/" + tenant_hash + "/menus"} className="ImportantLink">Karten
                                    bearbeiten</Link>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
            {tenant.enabled_features && tenant.enabled_features.includes('retrieve_contact_list') && (
                <Card className={classes.ListCard}>
                    <Card.Body className={classes.ListCardBody}>
                        <Row>
                            <Col>
                                <Link to={"/admin/" + tenant_hash + "/checkin-data/choosedate"}
                                      className="ImportantLink">Check-In Daten
                                    abrufen</Link>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </div>
    )
}

export default AdminHomepageScreen