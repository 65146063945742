import React from 'react';
import {applyClassName} from '../../utils/properties'

const FlexFill = props => {
  const {children, height} = props;

  const [fillHeight, setFillHeight] = React.useState('100');
  React.useEffect(() => {
    setFillHeight(height || '100')
  }, [height]);

  return (
    <div {...applyClassName(`d-flex flex-fill w-100 h-${fillHeight} flex-column`, props)}>
      {children}
    </div>
  );
};

export default FlexFill;
