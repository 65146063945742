import React, {useEffect, useState} from "react";
import axios from "../../axios/Instances/axios-instance";
import Card from "react-bootstrap/Card";
import './Admin.module.css'
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import useGlobal from "../../store/store";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import areas from '../../common/constants/promise-areas';
import Spinner from "../../components/UI/Spinner/Spinner";
import {Link} from "react-router-dom";

const AdminMenusScreen = (props) => {

    const [menus, setMenus] = useState([]);
    const tenant_hash = props.match.params.tenant_hash;
    const loading = usePromiseTracker({area: areas.tenant, delay: 500})
    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    );
    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];

    useEffect(() => {
        tenantActions.setTenant(tenant_hash);

        if (tenant.api_key)
            trackPromise(
                axios.get('/api/plugin/admin/v1/gastro/menu',
                    {
                        headers: {
                            'Authorization': 'Bearer ' + auth.jwtToken,
                            'api-key': tenant.api_key
                        }
                    })
                    .then(response => {
                        setMenus(response.data)
                    }),
                areas.tenant);
    }, [tenant_hash, auth.jwtToken, tenant.api_key, tenantActions]);

    if (loading.promiseInProgress) return <Spinner/>

    return (
        <div>
            <h6 className="titleMarginBottom">Speise und Getränkekarten bearbeiten</h6>
            {menus.map((menu, i) => (
                <Card className="ListCard" key={i}>
                    <Card.Body className="ListCardBody">
                        <Row>
                            <Col>{menu.name}</Col>
                            <Col className="text-right">
                                <Link
                                    className="ImportantLink"
                                    to={"/admin/" + tenant_hash + "/menu/" + menu.id}>Bearbeiten</Link>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))}
        </div>
    )

}

export default AdminMenusScreen;