import React from "react";
import Button from "react-bootstrap/Button";
import style from './GreenThemeButton.module.css';

const GreenThemeButton = (props) => {

    return <Button variant={"secondary"} {...props} className={style.GreenButton}>{props.children}</Button>

}

export default GreenThemeButton;