import React, {useEffect, useState} from "react";
import useAxiosWithJWT from '../../axios/axios-jwt';
import {Card} from "react-bootstrap";
import Spinner from '../../components/UI/Spinner/Spinner';
import Moment from "react-moment";
import Pluralize from 'react-pluralize';
import classes from './ShowTenantCheckinOverview.module.css'
import useGlobal from "../../store/store";
import log from "../../hoc/Logger";

const ShowTenantCheckinOverview = (props) => {

    const tenant_hash = props.match.params.tenant_hash;
    const [loading, setLoading] = useState(false);
    const [resource, setResource] = useState(undefined);
    const [checkinsSum, setCheckinsSum] = useState(0);
    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];

    const axios = useAxiosWithJWT();

    useEffect(() => {

        let sum = 0;

        if (resource) {

            log.debug(resource);
        }

        if (resource && resource.resources && resource.resources.length > 0) {
            resource.resources.map((item) => {
                if (item.checkins)
                    item.checkins.map((checkin) => {
                        sum = sum + 1;
                        if (checkin.additionals)
                            sum = sum + parseInt(checkin.additionals);
                        return checkin;
                    });
                return item;
            })
        }
        if (resource && resource.checkins && resource.checkins.length > 0) {
            resource.checkins.map((item) => {
                sum = sum + 1;
                if (item.additionals)
                    sum = sum + parseInt(item.additionals);
                log.debug(sum);
                return item;
            })
        }
        setCheckinsSum(sum);


        if (axios && !loading && !resource) {
            axios.get('/api/plugin/admin/v1/tenant/' + tenant_hash + '/checkins/current', {
                headers: {
                    'Authorization': 'Bearer ' + auth.jwtToken
                }
            })
                .then(response => {
                    setResource(response.data);
                    setLoading(false);
                })
                .catch(error => {
                });
            setLoading(true);
        }

    }, [loading, resource, tenant_hash, axios, auth])

    if (loading) return (<Spinner/>)

    return (
        <React.Fragment>
            <Card className={classes.TableCard} key="overview">
                <Card.Header>
                    Aktuell eingecheckte Gäste: {checkinsSum}
                </Card.Header>
            </Card>
            {resource && resource.resources && resource.resources.length > 0 &&
            resource.resources.map((item, i) => (
                <Card className={classes.TableCard} key={'res' + i}>
                    <Card.Header
                        className={(item.checkins) ? classes.greenBackground : classes.redBackground}>{item.name}</Card.Header>
                    <Card.Body key={'resbody' + i}>
                        {item.checkins && item.checkins.length > 0 && item.checkins.map((checkin, j) => (
                            <Card.Text key={'restext' + i + '.' + j}>
                                <b><Moment
                                    format="HH:mm">{checkin.addedAt}</Moment> Uhr</b>
                                {checkin.additionals > 0 &&
                                <span>{Number(checkin.additionals) > 0 && ' mit '}<Pluralize singular={'Gast'}
                                                                                             plural={'Gästen'}
                                                                                             zero={' ohne Gäste'}
                                                                                             count={Number(checkin.additionals)}/></span>}
                            </Card.Text>
                        ))}
                    </Card.Body>
                </Card>))
            }
            {resource && resource.checkins && resource.checkins.length > 0 && resource.checkins &&
            resource.checkins.map((item, i) => (
                <Card className={classes.TableCard} key={'chk' + i}>
                    <Card.Header
                        className={(item) ? classes.greenBackground : classes.redBackground}><Moment
                        format="HH:mm">{item.addedAt}</Moment> Uhr</Card.Header>
                    <Card.Body key={'chkbody' + i}>
                        <Card.Text key={'chktext' + i}>
                                <span>{Number(item.additionals) > 0 && 'mit '}<Pluralize singular={'Gast'}
                                                                                         plural={'Gästen'}
                                                                                         zero={'ohne Gäste'}
                                                                                         count={Number(item.additionals)}/></span>
                        </Card.Text>
                    </Card.Body>
                </Card>))
            }
        </React.Fragment>
    )
}

export default ShowTenantCheckinOverview;