import React from "react";
import appStoreLogo from '../../../assets/images/app_store_download.png';
import playStoreLogo from '../../../assets/images/google-play-badge-11.png';

const StoreButtons = (props) => {

    return (
        <React.Fragment>
            <a href="https://apps.apple.com/de/app/smartmeeting/id1529172629?l=de"
               style={{
                   display: 'inline-block',
                   borderRadius: '5px',
                   height: props.height + 'px',
                   marginRight: '20px'
               }}
            >
                <img src={appStoreLogo} height={props.height} width={props.height * 3.36}
                     alt="Jetzt im App Store"></img>

            </a>
            <a href='https://play.google.com/store/apps/details?id=online.smartmeeting.app.mainapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
               style={{
                   display: 'inline-block',
                   height: props.height + 'px',
                   boxSizing: 'border-box'
               }}>
                <img src={playStoreLogo} height={props.height} alt={"PlayStore"}></img>
            </a>
        </React.Fragment>
    )
}

export default StoreButtons;