import {Col, Form} from "react-bootstrap";
import classes from "../components/Forms/credentialForm.module.css";
import {ErrorMessage, Field} from "formik";
import * as Yup from "yup";
import {REQUIRED_CHECKBOX, REQUIRED_TEXTFIELD} from "../components/Forms/credentialBasics";
import React from "react";
import moment from "moment";
import log from "../hoc/Logger";
import SecureHTMLText from "../components/UI/Text/SecureHTMLText";

var _ = require('lodash');

export const updateInitialValuesAndValidation = (additionalFormBlueprint, form, schema, setSchema) => {
    additionalFormBlueprint.elements.forEach((element) => {
        switch (element.element_type) {
            case 'text_input_single_line':
                if (element.required) schema = schema.concat(Yup.object().shape({[element.identifier]: Yup.string().required(REQUIRED_TEXTFIELD).min(3, REQUIRED_TEXTFIELD)}));
                return form.current.initialValues[element.identifier] = '';
            case 'checkbox':
                if (element.required)
                    schema = schema.concat(Yup.object().shape({[element.identifier]: Yup.boolean().oneOf([true], REQUIRED_CHECKBOX),}));
                return form.current.initialValues[element.identifier] = false;
            case 'listbox':
                element.list_values.forEach(
                    (el) => {
                        log.debug(el);
                        if (el.default) form.current.initialValues[element.identifier] = el.identifier;
                    }
                );
                break;
            case 'datetime':

                return form.current.initialValues[element.identifier] = moment().format('yyyy-MM-DD') + "T" + moment().format('HH:mm');
            case 'date':
                return form.current.initialValues[element.identifier] = moment().format('yyyy-MM-DD');

            default:
                return null;
        }
    });
    setSchema(schema);
}


const getAdditionalFormElements = (credentialFormValidationSchema, additionalFormBlueprint, form) => {
    const additionalForm = [];

    additionalForm.push(
        <Form.Row key={additionalFormBlueprint.identifier}>
            <Form.Group as={Col}>
                <p className={classes.noPadding}><b>{additionalFormBlueprint.name}</b><br/>
                    <SecureHTMLText htmlInput={additionalFormBlueprint.description}/></p>
            </Form.Group>
        </Form.Row>);
    additionalFormBlueprint.elements.map((element) => {
        if (element.element_type === 'text_input_single_line') {
            additionalForm.push(<Form.Row key={element.identifier}>
                <Form.Group as={Col} controlId={element.identifier}>
                    <p className={classes.noPadding}><b><SecureHTMLText htmlInput={element.caption}/></b></p>
                    <Field type="text" as={Form.Control} name={element.identifier}
                           placeholder={element.description}
                           isInvalid={form.current.touched[element.identifier] && form.current.errors[element.identifier]}/>
                    <ErrorMessage as={Form.Control.Feedback} name={element.identifier} component="div"
                                  className={classes.ErrorMessage}/>
                </Form.Group>
            </Form.Row>);
        }
        if (element.element_type === 'checkbox') {
            additionalForm.push(
                <React.Fragment key={element.identifier}>
                    <Form.Row>
                        <Form.Group as={Col} controlId={element.identifier}>
                            <label className={"checkbox " + classes.CheckboxLabel + " " + classes.noMargin}>
                                <Field name={element.identifier} type="checkbox"
                                       className={classes.CheckboxStyle}
                                />
                                <b><SecureHTMLText htmlInput={element.caption}/></b>
                            </label>
                            <br/>
                            <label className={classes.noPadding}><i><SecureHTMLText
                                htmlInput={element.description}/></i></label>
                            <ErrorMessage as={Form.Control.Feedback} name={element.identifier}
                                          component="div"
                                          className={classes.ErrorMessage}/>
                        </Form.Group>
                    </Form.Row>
                </React.Fragment>
            );

        }

        if (element.element_type === 'listbox') {
            additionalForm.push(
                <Form.Row key={element.identifier}>
                    <Form.Group as={Col}>
                        <p className={classes.noPadding}><b><SecureHTMLText htmlInput={element.caption}/></b><br/>
                            <i><SecureHTMLText htmlInput={element.description}/></i></p>
                        <Form.Control as="select" name={element.identifier}
                                      onBlur={() => form.current.setFieldTouched(element.identifier, true)}
                                      onChange={(opt, e) => {
                                          form.current.setFieldValue(element.identifier, opt.target.value);
                                      }}
                                      options={{}}
                        >
                            {element.list_values.map((pair) => {
                                return (
                                    <option key={pair.identifier} value={pair.identifier}
                                            selected={pair.default}>{pair.caption}</option>
                                )

                            })}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
            );

        }

        if (element.element_type === 'datetime') {
            additionalForm.push(
                <Form.Row key={element.identifier}>
                    <Form.Group controlId={element.identifier} as={Col}>
                        <p className={classes.noPadding}><b><SecureHTMLText htmlInput={element.caption}/></b><br/>
                            <i><SecureHTMLText htmlInput={element.description}/></i></p>
                        <Field as={Form.Control} name={element.identifier} type="datetime-local"
                        ></Field>
                    </Form.Group>
                </Form.Row>
            );

        }

        if (element.element_type === 'date') {
            additionalForm.push(
                <Form.Row key={element.identifier}>
                    <Form.Group controlId={element.identifier} as={Col}>
                        <p className={classes.noPadding}><b><SecureHTMLText htmlInput={element.caption}/></b><br/>
                            <i><SecureHTMLText htmlInput={element.description}/></i></p>
                        <Field as={Form.Control} name={element.identifier} type="date"></Field>
                    </Form.Group>
                </Form.Row>
            );

        }
        return null;
    });
    return additionalForm;
}

export default getAdditionalFormElements

export const prepareAdditionalFormData = (data, tenant) => {
    const additionalFormData = {};
    tenant.additional_form.elements.forEach((element) => {
        if (element.element_type === 'date' || element.element_type === 'datetime')
            additionalFormData[element.identifier] = new Date(data[element.identifier]).toISOString();
        else
            additionalFormData[element.identifier] = data[element.identifier];
        _.unset(data, element.identifier);
    })
    return additionalFormData;
}