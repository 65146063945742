import React, {useEffect, useState} from "react";
import DatePicker, {registerLocale} from 'react-datepicker';
import useGlobal from "../../store/store";
import {usePromiseTracker} from "react-promise-tracker";
import promiseAreas from "../../common/constants/promise-areas";
import Spinner from "../../components/UI/Spinner/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import Button from "react-bootstrap/Button";
import axios from "../../axios/Instances/axios-instance";
import moment from "moment";


const ChooseDataDate = (props) => {
    registerLocale('de', de)

    const tenant_hash = props.match.params.tenant_hash;
    const [startDate, setStartDate] = useState(new Date());
    const loading = usePromiseTracker({area: promiseAreas.tenant, delay: 500})
    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    )
    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];

    const FileDownload = require('js-file-download');

    const loadData = () => {
        axios.get('/api/services/checkin/admin/v1/checkin-list/' + tenant.tenant_hash + '?date=' + moment(startDate).format('DD-MM-YYYY'),
            {
                headers: {
                    'Authorization': 'Bearer ' + auth.jwtToken,
                }
            })
            .then((response) => {
                FileDownload("\uFEFF" + response.data, tenant.tenant_hash + '_' + moment(startDate).format('DD-MM-YYYY') + '.csv', 'text/csv;charset=utf-8');
            });
    }

    useEffect(() => {
        if (!tenant.api_key || tenant.tenant_hash !== tenant_hash)
            tenantActions.setTenant(tenant_hash);
    }, [tenant, tenant_hash, tenantActions])

    if (loading.promiseInProgress) return <Spinner/>

    if (auth.jwtToken == null) return <div>Nicht angemeldet.</div>

    return (
        <React.Fragment>
            <p>Datenabruf für <b>{tenant.name}</b></p>
            <p>Bitte Datum auswählen:<br/><DatePicker locale="de" selected={startDate} dateFormat="dd.MM.yyyy"
                                                      onChange={date => setStartDate(date)}/></p>
            <p><Button variant={"secondary"} onClick={loadData}>Download</Button></p>
        </React.Fragment>

    );

}

export default ChooseDataDate