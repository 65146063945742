import React, {useEffect, useState} from "react";
import useAxiosWithJWT from "../../axios/axios-jwt";
import useGlobal from "../../store/store";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import promiseAreas from "../../common/constants/promise-areas";
import Moment from "react-moment";
import Spinner from "../../components/UI/Spinner/Spinner";
import log from "../../hoc/Logger";
import ScreenWithAdArea from "../../components/Layout/ScreenWithAdArea";
import Container from "react-bootstrap/Container";

const CheckOutScreen = (props) => {
    const store = require('store');
    const axios = useAxiosWithJWT();
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const auth = useGlobal(
        state => state.auth,
        actions => actions.auth
    )[0];
    const [tenant, tenantActions] = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    );
    const [user, userActions] = useGlobal(
        state => state.user,
        actions => actions.user
    );

    const loading = usePromiseTracker({area: promiseAreas.checkout});
    const generalLoading = usePromiseTracker({});

    useEffect(() => {

            if (!tenant.api_key && !loading.promiseInProgress) {
                tenantActions.setTenant(props.match.params.tenant_hash);
            }

            if (auth.jwtToken && tenant.api_key && !loading.promiseInProgress && !result && !error) {
                log.debug('here');
                trackPromise(
                    axios.post('/api/plugin/v1/checkout', null, {
                        headers: {
                            'Authorization': 'Bearer ' + auth.jwtToken,
                            'api-key': tenant.api_key
                        }
                    }).then((response) => {
                        if (response.data != null) {
                            setResult(response.data)
                            log.debug('[CheckOutScreen] setting CurrentCheckIn to null')
                            userActions.setCurrentCheckIn(null);
                        } else
                            setResult('none')
                    }).catch((error) => {
                            log.debug(error.response.data)
                            setError(error.response.data.message);
                        }
                    ), promiseAreas.checkout
                );
            }


            if (!auth.jwtToken && user.currentCheckIn && user.currentCheckIn.request_id && user.currentCheckIn.opt_in_code && tenant.api_key && !loading.promiseInProgress && !result && !error)
                trackPromise(
                    axios.post('/api/plugin/v1/checkout/' + user.currentCheckIn.request_id + '/' + user.currentCheckIn.opt_in_code, null, {headers: {'api-key': tenant.api_key}}).then((response) => {
                        if (response.data != null) {
                            setResult(response.data)
                            log.debug('[CheckOutScreen] setting CurrentCheckIn to null')
                            userActions.setCurrentCheckIn(null);
                        } else
                            setResult('none')
                    }).catch((error) => {
                            log.debug(error.response.data)
                            setError(error.response.data.message);
                        }
                    ), promiseAreas.checkout
                );

        },
        [auth.jwtToken, tenant.api_key, tenantActions, props.match.params.tenant_hash, axios, result, loading.promiseInProgress, userActions, error, store, user.currentCheckIn]
    )

    if (loading.promiseInProgress || generalLoading.promiseInProgress) return <Spinner/>

    if (!tenant.tenant_hash) return null;

    var checkoutBody = '';

    if (result === 'none') {
        checkoutBody = <React.Fragment>
            <p><b>Sie waren nicht mehr eingecheckt.</b></p>
            <p>Kein aktueller Check-In bei <b>{tenant.name}</b> gefunden.</p>
        </React.Fragment>
    }

    if (result && checkoutBody === '') {
        checkoutBody = <React.Fragment>
            <p><b>Erfolgreich ausgecheckt</b></p>
            <p>Ihr Check-In bei <b>{result.tenant_name}</b> vom <b><Moment
                format="DD.MM.YYYY HH:mm">{result.check_in_at}</Moment></b> ist jetzt beendet.</p>
        </React.Fragment>
    }

    if (error) checkoutBody = error;

    if ((!store.get('user_jwt_token') || !user.currentCheckIn) && checkoutBody === '') checkoutBody = <React.Fragment>
        <p><b>Nicht angemeldet</b></p>
        <p>Sie sind nicht angemeldet und es wurde kein aktueller Check-In bei <b>{tenant.name}</b> gefunden.</p>
    </React.Fragment>;

    return (
        <ScreenWithAdArea logoUrl={tenant.logo_url} adType={"bottom_ad_checkout"}>
            <Container>{checkoutBody}</Container>
        </ScreenWithAdArea>
    );
}

export default CheckOutScreen;