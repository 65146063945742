import React, {useEffect, useState} from 'react'
import classes from './AdArea.module.css'
import axiosInstance from "../../../axios/Instances/axios-instance";
import useGlobal from "../../../store/store";
import log from "../../../hoc/Logger";
import {useMounted} from "../../../hooks/Mounted";

const AdArea = ({type}) => {

    const [message, setMessage] = useState('');

    const tenant = useGlobal(
        state => state.tenant,
        actions => actions.tenant
    )[0];

    const user = useGlobal(
        state => state.user,
        actions => actions.user
    )[0];

    const mounted = useMounted();

    useEffect(() => {

            let tenant_hash = tenant.tenant_hash;
            let requestType = type;
            if (!requestType) requestType = 'bottom_ad_checkin';

            if (tenant_hash === null) tenant_hash = user.currentCheckIn.tenant_hash;

            axiosInstance.get("/api/services/messages/v1/sm-bottom-ad", {
                params: {"type": requestType, "lang": "de_DE", "tenant_hash": tenant_hash}
            }).then(response => {
                if (mounted.current)
                    setMessage(response.data.message);
            }).catch(err => {
                log.debug(err);
            });
        }
    );


    return (
        <div className={classes.AdArea}>
            <div className={classes.AdAreaSeparator}/>
            <div className={classes.AdText}>
                <div dangerouslySetInnerHTML={{__html: message}}/>
            </div>
        </div>
    )
}

export default AdArea;

/* final data = {
      'type': type,
      'lang': 'de_DE',
    };

    if (currentCheckin != null) // print(currentCheckin.id);
    if (tenantHash != null) data['tenant_hash'] = tenantHash;
    if (currentCheckin != null)
      data['checkin_id'] = currentCheckin.id.toString();

    // print(data);
    var response = await retry(
      // Make a GET request
      () => http.get(
        Uri.https('api.smartmeeting.online',
            '/api/services/messages/v1/sm-bottom-ad', data),
        headers: {HttpHeaders.contentTypeHeader: 'application/json'},*/
