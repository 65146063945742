import React from "react";
import {Container} from "react-bootstrap";
import {IconContext} from "react-icons";
import useGlobal from "../../store/store";
import log from "../../hoc/Logger";
import {RiCheckboxCircleLine} from "react-icons/ri/index";
import Moment from "react-moment";
import {GoLocation} from "react-icons/go/index";
import {FaRegCalendar} from "react-icons/fa/index";
import IconWithText from "../../components/UI/Elements/IconWithText";
import {MdTimelapse} from "react-icons/md/index";
import {IoIosDocument, IoMdPerson} from 'react-icons/io/index';
import GreenThemeButton from "../../components/UI/Button/GreenThemeButton";
import ScreenWithAdArea from "../../components/Layout/ScreenWithAdArea";
import {Link} from "react-router-dom";

const ShowCurrentCheckInScreen = (props) => {

    const user = useGlobal(
        state => state.user,
        actions => actions.user
    )[0];

    const loggedInUser = useGlobal(
        state => state.auth.loggedInUser,
        actions => actions
    )[0];

    log.debug(user.currentCheckIn)
    log.debug(loggedInUser)

    if (!loggedInUser && !user.currentCheckIn)
        return <h5>Sie sind aktuell nicht eingeloggt.</h5>

    if (!user.currentCheckIn)
        return <h5>Sie sind aktuell nirgends eingecheckt.</h5>;

    let registerAccountAlert = null;

    if (!loggedInUser) {
        registerAccountAlert = (
            <div style={{textAlign: "center", paddingBottom: "1em"}}>
                <p>Nächstes mal mit einem Klick einchecken?<br/>
                    <Link to={{
                        pathname: '/account/register',
                        state: {}
                    }}><span style={{color: "#00a0de"}}>Jetzt persönlichen Account registrieren</span></Link>
                </p>
            </div>
        );
    }


    let initiatorString = user.currentCheckIn.initiator;

    if (user.currentCheckIn.attendees.length > 0) initiatorString = initiatorString + ', ' + user.currentCheckIn.attendees.map((item, i) => {
        return (
            item
        );
    }).join(', ');

    const body = (
        <>
            <Container className="">
                <p>CHECK-IN</p>
            </Container>
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                    marginTop: "-30px",
                    height: "120px"
                }}>
                <IconContext.Provider value={{color: 'white', size: '100%',}}>
                    <RiCheckboxCircleLine/>
                </IconContext.Provider>
            </div>

            <Container>
                <p>
                    <IconWithText text={user.currentCheckIn.tenant_name.toUpperCase()}>
                        <GoLocation/>
                    </IconWithText>
                </p>
                <p>
                    <IconWithText text={<Moment
                        format="DD.MM.YYYY HH:mm">{user.currentCheckIn.check_in_at}</Moment>}>
                        <FaRegCalendar/>
                    </IconWithText>
                </p>
                <p>
                    <IconWithText text={`noch für ${user.currentCheckIn.checkin_valid_for} Minuten`}>
                        <MdTimelapse/>
                    </IconWithText>
                </p>
                {user.currentCheckIn.resource_name && <p>
                    <IconWithText text={user.currentCheckIn.resource_name}>
                        <IoIosDocument/>
                    </IconWithText>
                </p>}
                <p>
                    <IconWithText
                        text={initiatorString}>
                        <IoMdPerson/>
                    </IconWithText>
                </p>
            </Container>
            {
                user.currentCheckIn.menu_path && (<Container style={{marginTop: "2em"}}>
                    <GreenThemeButton variant="secondary" onClick={() => {
                        props.history.push(user.currentCheckIn.menu_path)
                    }}>Zur Speisekarte</GreenThemeButton>
                </Container>)
            }
        </>
    );


    return (
        <>
            {registerAccountAlert}
            <ScreenWithAdArea success children={body} logoUrl={user.currentCheckIn.tenant_logo_url}/>
        </>
    );
}

export default ShowCurrentCheckInScreen